import './App.css'
import { useEffect, useState } from 'react'
import { Routes, Route, useHistory } from 'react-router-dom'

import WalletConnectProvider from '@walletconnect/web3-provider'
import { useEthers, ChainId } from '@usedapp/core'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Sidebar from './components/Sidebar'
import SidebarMobile from './components/SidebarMobile'
import DashboardPage from './pages/DashboardPage'
import StakePage from './pages/StakePage'
import LogsPage from './pages/LogsPage'
import GinConversion from './pages/GinConversion'
import ProcreationPage from './pages/ProcreationPage'
import MicrosoftLoginPage from './pages/MicrosoftLoginPage'
import KickPage from './pages/KickPage'


function App() {
  const {
    activateBrowserWallet,
    account,
    activate,
    deactivate,
    chainId,
    error
  } = useEthers()

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    }
  });
  
  useEffect(() => {
    console.log('chainId', chainId)
    console.log('ChainId.Mainnet', ChainId.Mainnet)
    if (chainId && ChainId.BSC!== chainId && ChainId.Mainnet!== chainId) {
      toast.error('Connect to BNB Chain and refresh page', {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    // const checkWalletConnectSession = async () => {
    //   if (window.localStorage.walletconnect) {
    //     const provider = new WalletConnectProvider({
    //       qrcode: true,
    //       bridge: 'https://bridge.walletconnect.org',
    //       rpc: {
    //         [ChainId.BSC]: process.env.REACT_APP_BSC_RPC,
    //       },
    //     })
    //     await provider.enable()
    //     activate(provider)
    //     activateBrowserWallet()
    //   }
    // }

    // checkWalletConnectSession()
  }, [chainId])
  return (
    <>
      <ToastContainer />
          <div className="flex min-h-screen overflow-y-auto relative">
            {
              window.location.pathname !== '/linkMinecraft' && (
                <>
                  <div className="flex-shrink lg:block hidden">
                    <Sidebar />
                  </div>
                  <div className="flex-shrink lg:hidden block relative">
                    <SidebarMobile />
                  </div>
                </>
              )
            }
              <div className="flex-shrink w-full relative">
                <Routes>
                  <Route exact path="/" element={<DashboardPage />} />
                  <Route exact path="/stake" element={<StakePage />} />
                  <Route exact path="/logs" element={<LogsPage />} />
                  <Route exact path="/kick" element={<KickPage />} />
                  <Route exact path="/gin-conversion" element={<GinConversion />} />
                  <Route exact path="/procreation" element={<ProcreationPage />} />
                  <Route exact path="/linkMinecraft" element={<MicrosoftLoginPage />} />
                </Routes>
                <div className="text-xs text-[#EEFFFD] font-sans absolute bottom-6 2xl:px-52 xl:px-28 lg:px-20 px-2">
                © 2024 Tipsy App by The Tipsy Company | The Tipsy Company is a registered entity under the Republic of the Marshall Islands (Reg. No. 114167)
              </div>
              </div>
          </div>
    </>
  )
}

export default App
