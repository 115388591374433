import { useCalls, useCall } from '@usedapp/core';
import { Contract, utils } from 'ethers';
import { useEffect, useState } from 'react';
import PancakeswapRouterAbi from '../../abi/PancakeswapRouterAbi.json';
import Web3 from 'web3'

const pancakeswapContractAddress = process.env.REACT_APP_CAKE_ADDRESS
const pancakeswapContractInterface = new utils.Interface(PancakeswapRouterAbi);
const pancakeswapContract = new Contract(
  pancakeswapContractAddress,
  pancakeswapContractInterface
);

export function useGetTipsyPriceInUSD()  {
  const [tipsyToUsdt, setTipsyToUsdt] = useState(undefined);
  const [pending, setPending] = useState(false);

  var price = utils.parseUnits('1', 'ether');
  var bnbToTipsyPair = [process.env.REACT_APP_BNB_ADDRESS, process.env.REACT_APP_TIPSY_COIN_CONTRACT];
  var bnbToUSDTPair = [process.env.REACT_APP_BNB_ADDRESS, process.env.REACT_APP_USDT_ADDRESS];
  var pairs = [bnbToTipsyPair, bnbToUSDTPair]
  const calls = pairs?.map(pair => ({
    contract: pancakeswapContract,
    method: 'getAmountsOut',
    args: [price, pair]
  })) ?? []

  const results = useCalls(calls) ?? []
  // results.forEach((result, idx) => {
  //   if(result && result.error) {
  //     setPending(false);
  //     console.error(`Error encountered calling: =>  result.error`)
  //   }
  // })
  useEffect(() => {
    setPending(true);
    if (results.length > 1 && results[0]?.value?.amounts?.[1] !== undefined && results[1]?.value?.amounts?.[1] !== undefined) {
      var val1 = results[0]?.value?.amounts?.[1].toString();
      var val2 = results[1]?.value?.amounts?.[1].toString();
      if (val1 && val2) {
        var oneBnbToTipsy = parseInt(Number(val1) / Math.pow(10,18));
        var oneBnbToUsdt = parseInt(Number(val2) / Math.pow(10,18));
        var oneTipsyPriceInUsd = Number(oneBnbToUsdt / oneBnbToTipsy).toFixed(10);
        setTipsyToUsdt(oneTipsyPriceInUsd * 0.995);
      } else {
        console.error("Error: One or more values are undefined.");
      }
    } else {
      console.error("Error: Results are not as expected.");
    }
    setPending(false);
  }, [results]);

  return { tipsyToUsdt };
  
}

