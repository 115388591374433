import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { ChainId, DAppProvider, Goerli, Mainnet, MetamaskConnector } from '@usedapp/core';
import { WalletConnectV2Connector } from '@usedapp/wallet-connect-v2-connector'

import './index.css'
import App from './App'

import reportWebVitals from './reportWebVitals'
import { getDefaultProvider } from 'ethers';
require('dotenv').config()

const BSCMainnetChain = {
  chainId: 56,
  chainName: 'BSC Mainnet',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb', // specific BSC Mainnet multicall address
  getExplorerAddressLink: (address) => `https://bscscan.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://bscscan.com/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: process.env.REACT_APP_BSC_RPC,
  blockExplorerUrl: 'https://bscscan.com',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  }
}

const config = {
  pollingInterval: 5000,
  autoConnect: true,
  readOnlyChainId: ChainId.BSC,
  readOnlyUrls: {
    [ChainId.BSC]: process.env.REACT_APP_BSC_RPC,
    [ChainId.Mainnet]: process.env.REACT_APP_ETH_RPC
    // [ChainId.Mainnet]: getDefaultProvider('mainnet')
  },
  multicallVersion: 1,
  fastMulticallEncoding: true,
  noMetamaskDeactivate: true,
  connectors: {
    metamask: new MetamaskConnector(),
    walletConnectV2: new WalletConnectV2Connector({
      projectId: '0bd1ef7b8cc6d19fc43d7b2eaa5318fb',
      chains: [BSCMainnetChain],
      rpcMap: {
        56: 'https://bsc-dataseed.binance.org'
      },
    }),
  },
};


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <DAppProvider config={config}>
        <App />
      </DAppProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals()