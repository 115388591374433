import React, { useEffect, useState } from 'react';
import { useCall, useCalls, useEthers } from '@usedapp/core';
import { Contract, utils, ethers } from 'ethers';
import Web3 from 'web3';

import TipsyCoinAbi from '../../abi/TipsyCoinAbi.json';
import { TIPSY_COIN_CONTRACT, TIPSY_STAKE_CONTRACT } from '../../common/environmentVariables';

const tipsyCoinAddress = `${TIPSY_COIN_CONTRACT}`;
const tipsyCoinContractInterface = new utils.Interface(TipsyCoinAbi);
const tipsyCoinContract = new Contract(
  tipsyCoinAddress,
  tipsyCoinContractInterface
);

export function useBalanceOf()  {
  const { account } = useEthers()
  const [balanceOf, setBalanceOf] = useState(undefined);
  const [pending, setPending] = useState(false);
  const { value, error } =
    useCall(
      account &&
      {
        contract: tipsyCoinContract,
        method: 'balanceOf',
        args: [account],
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setBalanceOf(value?.[0]);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setBalanceOf(undefined);
    }
    // console.log('WHAT AM I GETTING: useBalanceOf', value, error)
  }, [value, error]);
  return { balanceOf, error, pending };
}

export function useBalanceOfStakingContract()  {
  const { account } = useEthers()
  const [balanceOfStakingContract, setBalanceOfStaking] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      {
        contract: tipsyCoinContract,
        method: 'balanceOf',
        args: [TIPSY_STAKE_CONTRACT],
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setBalanceOfStaking(value?.[0] / 1e18);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setBalanceOfStaking(undefined);
    }
    // console.log('WHAT AM I GETTING: useBalanceOfStakingContract', value, error)
  }, [value, error]);

  return { balanceOfStakingContract, error, pending };
}

export function useGetAllowance()  {
  const { account } = useEthers()
  const [userAllowance, setUserAllowance] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account &&
      {
        contract: tipsyCoinContract,
        method: 'allowance',
        args: [account, TIPSY_STAKE_CONTRACT],
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setUserAllowance((value?.[0] / 1e18));
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setUserAllowance(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetAllowance', value, error)
  }, [value, error]);

  return { userAllowance, error, pending };
}
export function useTotalSupply()  {
  const { account } = useEthers()
  const [totalSupply, setTotalSupply] = useState(undefined);
  const [pending, setPending] = useState(false);
  const { value, error } =
    useCall(
      {
        contract: tipsyCoinContract,
        method: 'totalSupply',
        args: [],
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setTotalSupply(value?.[0] / 1e18);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setTotalSupply(undefined);
    }
    // console.log('WHAT AM I GETTING: useTotalSupply', value, error)
  }, [value, error]);
  return { totalSupply, error, pending };
}