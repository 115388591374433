import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import ConnectWalletModal from "./connectWalletModal";
import SidebarFooter from "../components/SidebarFooter";
import Logo from "../assets/images/tipsycoin.svg";

export default function SidebarMobile() {
  const { account } = useEthers();
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(true);

  function displayAccount() {
    if (account !== undefined && account) {
      return (
        <span>
          {account.substring(0, 8)}...{account.substring(36, 42)}
        </span>
      );
    }
  }

  return (
    <>
      <div
        className={`fixed top-0 left-0 ${
          open ? "w-80" : "w-0"
        } sidebar-shadow z-20 duration-300 bg-[#292929] h-full text-lg text-white uppercase`}
      >
        {!open && (
          <div className="relative w-full">
            <div
              onClick={() => setOpen(!open)}
              className="p-4 z-30 space-y-2 bg-[#1B1B1B] top-0 -right-18 absolute cursor-pointer lg:hidden"
            >
              <span className="block w-8 h-0.5 bg-gray-100 animate-pulse"></span>
              <span className="block w-8 h-0.5 bg-gray-100 animate-pulse"></span>
              <span className="block w-8 h-0.5 bg-gray-100 animate-pulse"></span>
            </div>
          </div>
        )}

        <div
          className={`fixed h-screen flex flex-col items-center w-80 bg-[#292929] ${
            !open && "hidden"
          } duration-200 relative`}
        >
          {open && (
            <div
              onClick={() => setOpen(!open)}
              className="mt-2 mr-2 z-30 cursor-pointer duration-200 space-y-2 text-2xl rounded-full top-0 right-0 absolute lg:hidden"
            >
              X
            </div>
          )}
          <div className="flex-1 flex flex-col overflow-y-auto mt-10">
            <Link to="/" className="flex items-center">
              <img src={Logo} className="h-12" alt="Flowbite Logo" />
            </Link>
            <p className="font-sans text-xs text-[#EEFFFD] opacity-100 mt-20">
              Wallet Address: <br />
              {account && <>{displayAccount()}</>}
              {!account && (
                <span
                  className="underline cursor-pointer hover:text-[#FB8189]"
                  onClick={() => setShowModal(true)}
                >
                  Connect Wallet
                </span>
              )}
            </p>

            <ul className="space-y-5 mt-16">
              <li>
                <Link
                  to="/"
                  onClick={() => setOpen(false)}
                  className="flex items-center py-2 rounded-lg hover:text-[#FB8189]"
                >
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/stake"
                  onClick={() => setOpen(false)}
                  className="flex items-center py-2 rounded-lg hover:text-[#FB8189]"
                >
                  <span>Stake $Tipsy</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/gin-conversion"
                  onClick={() => setOpen(false)}
                  className="flex items-center py-2 rounded-lg hover:text-[#FB8189]"
                >
                  <span>Convert Gin</span>
                </Link>
              </li>

              {/* <li>
                <Link
                  to="#"
                  className="flex items-center py-2 rounded-lg text-[#535656]"
                >
                  <span>Your NFTS</span>
                </Link>
              </li>


              <li>
                <Link
                  to="#"
                  className="flex items-center py-2 rounded-lg text-[#535656]"
                >
                  <span>TipsyShop</span>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="flex items-center py-2 rounded-lg text-[#535656]"
                >
                  <span>Procreation</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="flex items-center py-2 rounded-lg text-[#535656]"
                >
                  <span>Play TipsyVerse</span>
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="flex-shrink-0 flex mb-6">
            <SidebarFooter />
          </div>
        </div>
      </div>
      {showModal && <ConnectWalletModal onHide={() => setShowModal(false)} />}
    </>
  );
}
