import React from 'react'

export default function MicrosoftLoginPage() {
  return (
    <div className='bg-black h-full'>
      <div className="flex h-full text-white">
        <div className="w-1/2 flex items-center justify-center">
            <div className="">
              <div><span>Login</span> <br/>to your Minecraft account</div>
              <button type="button" className="uppercase text-sm">Sign in with microsoft</button>
              <div></div>
            </div>
        </div>
        <div className="w-1/2"></div>
      </div>
    </div>
  )
}
