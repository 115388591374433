import React, { useEffect, useState } from "react";
import { useEthers, useContractFunction } from "@usedapp/core";
import { toast } from "react-toastify";

import ConnectWalletModal from "../components/connectWalletModal";
import TipsyStakeAbi from "../abi/TipsyStakeAbi.json";
import { TIPSY_STAKE_CONTRACT } from "../common/environmentVariables";
import { Contract, utils } from "ethers";

// tipsy contract instance to send transaction
const tipsyStakeContractAddress = `${TIPSY_STAKE_CONTRACT}`;
const tipsyStakeContractInterface = new utils.Interface(TipsyStakeAbi);
const tipsyStakeContract = new Contract(
  tipsyStakeContractAddress,
  tipsyStakeContractInterface
);

export default function KickPage() {
  const { account, deactivate } = useEthers();
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const tipsyKickHandler = useContractFunction(tipsyStakeContract, "kick", {
    transactionName: "Wrap",
  });

  useEffect(() => {
    if (tipsyKickHandler.state.status === "PendingSignature") {
      toast.warning("Transaction in progress...", {
        autoClose: 20000,
      });
    }
    if (tipsyKickHandler.state.status === "Success") {
      setDisabled(false);
      toast.success("Transaction submitted.", {
        autoClose: 20000,
      });
    }
    if (tipsyKickHandler.state.status === "Exception") {
      setDisabled(false);
      toast.error(tipsyKickHandler.state.errorMessage, { autoClose: 20000 });
    }
    if (tipsyKickHandler.state.status === "Fail") {
      setDisabled(false);
      toast.error(tipsyKickHandler.state.errorMessage, { autoClose: 20000 });
    }

    delete tipsyKickHandler.state["status"];
  }, [tipsyKickHandler.state]);

  function kickHandler() {
    if (!account) toast.error("Connect your account");
    setDisabled(true);
    try {
      tipsyKickHandler.send();
    } catch (error) {
      setDisabled(false);
      toast.error(error?.message);
      console.log("error", error?.message);
    }
  }

  const disconnectWallet = () => {
    deactivate();
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("shouldConnectMetamask");
  };

  function buyTipsy(url) {
    window.open(url, "_blank");
  }

  return (
    <>
      <div className="2xl:px-52 xl:px-28 lg:px-20 px-2 py-20 text-[#EEFFFD] font-sans">
        <div className="mb-10 flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:items-center sm:justify-end">
          <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:items-center sm:justify-between sm:space-x-2">
            <button
              type="button"
              onClick={() =>
                buyTipsy(
                  "https://pancakeswap.finance/swap?outputCurrency=0xe097bcEb09bfb18047Cf259F321cC129b7bEba5e"
                )
              }
              className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
            >
              BUY $TIPSY
            </button>
            {!account ? (
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Connect WALLET
              </button>
            ) : (
              <button
                type="button"
                onClick={() => disconnectWallet()}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Disconnect WALLET
              </button>
            )}
          </div>
        </div>
        <p>
          If there's a mismatch on the TispyApp between your deposited $tipsy
          amount and your staking Tier, you can try this button to re-sync your
          account in our Staking smart contract. This should correct a bug for
          users who deposited 100 or 200 million $tipsy, but received a staking
          Tier lower than expected. <br />
          <br />
          Clicking the button will prompt your wallet to send the kick() method
          to our staking contract at: 
          <b> 0xad1c1a04bb050530c2511d4113b81ed7396e3fb3</b>, which you will need
          to approve in your wallet. Your staking $tipsy balance won't be
          changed, and no extra $tipsy will be taken from you. Your $tipsy lock
          duration won't reset or change. <br />
          <br />
          Once the transaction has been confirmed, please wait a few seconds and
          then reload the TipsyApp page and check to see if your Tier has been
          upgraded. If it's still incorrect, please respond to your support
          ticket to let us know, or open a new support ticket on our{" "}
          <a href="https://discord.gg/thetipsycompany" target="_blank">Discord</a>.
        </p>
        <div className="flex h-full w-full items-center justify-center mt-10">
          <button
            type="button"
            disabled={!account}
            onClick={() => kickHandler()}
            className={`${
              !account ? "cursor-not-allowed" : "cursor-pointer"
            } border-[#F631A7] hover:bg-[#F631A7] uppercase border hover:text-[#EEFFFD] rounded-[27px] text-sm lg:w-72 w-full 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white`}
          >
            Kick
          </button>
        </div>
      </div>
      {showModal && <ConnectWalletModal onHide={() => setShowModal(false)} />}
    </>
  );
}
