import React from 'react'
import InstagramImg from '../assets/images/instagram.png'
import DiscordImg from '../assets/images/discord.png'
import TwitterImg from '../assets/images/twitter.svg'
import TipsyImg from '../assets/images/tipsy-icon.svg'
import TelegramImg from '../assets/images/telegram.svg'

export default function Footer() {
  return (
    <div className="flex flex-row items-center justify-between space-x-5">
      <a href="https://thetipsycompany.com" target="_blank">
        <img src={TipsyImg} alt="" className="w-7 h-7" />
      </a>
      <a href="https://discord.gg/thetipsycompany" target="_blank">
        <img src={DiscordImg} alt="" className="w-6 h-6" />
      </a>
      <a href="https://t.me/TipsyCoinOfficial" target="_blank">
        <img src={TelegramImg} alt="" className="w-6 h-6" />
      </a>
      <a href="https://twitter.com/TipsyCoin" target="_blank">
        <img src={TwitterImg} alt="" className="w-6 h-6" />
      </a>
      <a href="https://www.instagram.com/tipsycoin.io/" target="_blank">
        <img src={InstagramImg} alt="" className="w-6 h-6" />
      </a>
    </div>
  )
}
