import React, { useEffect, useState } from 'react'

export default function ProcreationPage() {

  return (
    <>
      <div className="lg:px-56 px-4 py-20 text-[#EEFFFD] font-sans">
        <h2 className="mb-1 text-4xl uppercase">Procreation</h2>
        <p className="text-xs text-[#EEFFFD]">Create Opus Penguins with the use of Enchanted Jellybeans on Genesis Penguins.</p>
      </div>
    </>
  )
}
