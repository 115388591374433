import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

const MinecraftModal = (props) => {
  return (
    <>
      <div className="flex justify-center items-center font-roboto overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative lg:w-[23%] sm:w-2/3 w-[90%] my-6 mx-auto">
          <div className="bg-[#1B1B1B] border border-[#626262] rounded-[36px] tipsy-shadow relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-6 flex-auto">
              <h3 className="text-[#EEFFFD] text-sm">LINK WALLET WITH YOUR MINECRAFT ACCOUNT</h3>
              <hr className="my-4 border-[#707070]" />
              <p className="text-[#EEFFFD] text-xs">If you play TipsyVerse, it is highly recommended that you link your wallet with your Minecraft account. This ensures that you get the most out of TipsyVerse. <br/><br/> You only need to link it once.</p>

              <div>
                <Link to="/linkMinecraft" className="border-[#F631A7] border rounded-[27px] text-sm px-6 py-1 mr-3 mt-4 text-center text-[#F631A7] uppercase">Link Account</Link>
                <button onClick={props.onHide} type="button" className="border-[#EEFFFD] border rounded-[27px] text-sm px-6 py-1 mt-4 text-center text-[#EEFFFD] uppercase">Later</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MinecraftModal
