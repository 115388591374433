import React, { useEffect, useState } from "react";
import { useCall, useCalls, useEthers } from "@usedapp/core";
import { Contract, utils, ethers, getDefaultProvider } from "ethers";

import TipsyStakeAbi from "../../abi/TipsyStakeAbi.json";
import TipsyGinAbi from "../../abi/TipsyGinAbi.json";
import GoerliGinAbi from "../../abi/GoerliGinAbi.json";
import {
  TIPSY_STAKE_CONTRACT,
  TIPSY_GIN_CONTRACT,
  GOERLI_GIN_CONTRACT
} from "../../common/environmentVariables";
import { formatUnits } from "ethers/lib/utils";

const tipsyStakeAddress = `${TIPSY_STAKE_CONTRACT}`;
const tipsyStakeContractInterface = new utils.Interface(TipsyStakeAbi);
const tipsyStakeContract = new Contract(
  tipsyStakeAddress,
  tipsyStakeContractInterface
);

const bscProviderUrl =
  "https://sparkling-solitary-field.bsc.quiknode.pro/8282f393ed1ceb08bd41ab18a5e55ce5806fd088/";
const bscProvider = new ethers.providers.JsonRpcProvider(bscProviderUrl);

const bscGinAddress = `${TIPSY_GIN_CONTRACT}`;
const bscGinContractInterface = new utils.Interface(TipsyGinAbi);
const bscGinContract = new Contract(
  bscGinAddress,
  bscGinContractInterface,
  bscProvider
);

const ethProviderUrl = "https://wider-little-pool.quiknode.pro/e6efb29b68088a09f90f8dc0dacf3691834b8036";
const ethProvider = new ethers.providers.JsonRpcProvider(ethProviderUrl);

const ethGinAddress = `${GOERLI_GIN_CONTRACT}`;
const ethGinContractInterface = new utils.Interface(GoerliGinAbi);

// Later in your code where you instantiate the ethGinContract
const ethGinContract = new Contract(
  ethGinAddress,
  ethGinContractInterface,
  ethProvider
);
export function useGetAllowance() {
  const { account } = useEthers();
  const [userAllowance, setUserAllowance] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && {
        contract: bscGinContract,
        method: "allowance",
        args: [account, account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setUserAllowance(value?.[0] / 1e18);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setUserAllowance(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetAllowance', value, error)
  }, [value, error]);

  return { userAllowance, error, pending };
}

export function useGetAllocatedGin() {
  const { account, chainId } = useEthers();
  const [allocatedGin, setAllocatedGin] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && chainId === 56 && {
        contract: tipsyStakeContract,
        method: "getAllocatedGin",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setAllocatedGin(value?.[0] / 1e18);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setAllocatedGin(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetAllocatedGin', value, error)
  }, [value, error]);

  return { allocatedGin, error, pending };
}

export function useGetGinBalance() {
  const { account, chainId } = useEthers();
  const [ginBalance, setGinBalance] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && {
        contract: bscGinContract,
        method: "balanceOf",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      const formattedBalance = formatUnits(value?.[0], 18);
      console.log('chainId', chainId)
      console.log('GIN Balance => value?.[0]', formattedBalance);
      setGinBalance(parseFloat(formattedBalance).toString());
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setGinBalance(undefined);
    }
    // console.log(
    //   "WHAT AM I GETTING: useGetGinBalance",
    //   value?.[0] / 1e18,
    //   error
    // );
  }, [value, error]);

  return { ginBalance, error, pending };
}


export function useGetGoerliGinBalance() {
  const { account, chainId } = useEthers();
  const [goerliGinBalance, setGoerliGinBalance] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && {
        contract: ethGinContract,
        method: "balanceOf",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      const formattedBalance = formatUnits(value?.[0], 18);
      console.log('GOERLI GIN Balance => value?.[0]', formattedBalance);
      setGoerliGinBalance(parseFloat(formattedBalance).toString());
      setPending(false);
    }
    if (error) {
      console.error(error);
      setPending(false);
      setGoerliGinBalance(undefined);
    }
    // console.log(
    //   "WHAT AM I GETTING: useGetEthGinBalance",
    //   value,
    //   error
    // );
  }, [value, error]);

  return { goerliGinBalance };
}

export function useGetWhiteListed() {
  const { account, chainId } = useEthers();
  const [isWhiteListed, setIsWhiteListed] = useState(false);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && chainId !== 1 && {
        contract: bscGinContract,
        method: "betaWhitelist",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setIsWhiteListed(value?.[0]);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setIsWhiteListed(false);
    }
    // console.log(
    //   "WHAT AM I GETTING: useGetWhiteListed",
    //   value,
    //   error
    // );
  }, [value, error]);

  return { isWhiteListed };
}

export function useGetUserLvlTxt_Cached() {
  const { account } = useEthers();
  const [userLvlTxt, setUserLvlTxt] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && {
        contract: tipsyStakeContract,
        method: "getUserLvlTxt_Cached",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setUserLvlTxt(value?.[0].toString());
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setUserLvlTxt(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetUserLvlTxt_Cached', value, error)
  }, [value, error]);

  return { userLvlTxt, error, pending };
}

export function useGetUserInfo() {
  const { account, chainId } = useEthers();
  const [userInfoMap, setUserInfo] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && chainId === 56 && {
        contract: tipsyStakeContract,
        method: "userInfoMap",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setUserInfo(value);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setUserInfo(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetUserInfo', value, error)
  }, [value, error]);

  return { userInfoMap, error, pending };
}

export function useGetTotalWeight() {
  const { account, chainId } = useEthers();
  const [totalWeight, setTotalWeight] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && chainId === 56 && {
        contract: tipsyStakeContract,
        method: "totalWeight",
        args: []
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setTotalWeight(value?.[0] / 1e18);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setTotalWeight(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetTotalWeight', value, error)
  }, [value, error]);

  return { totalWeight, error, pending };
}

export function useGetClaimableTipsy() {
  const { account } = useEthers();
  const [claimableTipsy, setClaimableTipsy] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && {
        contract: tipsyStakeContract,
        method: "harvestCalc",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      // setClaimableTipsy((value?.[0] / 1e18).toFixed(2));
      setClaimableTipsy(value?.[0]);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setClaimableTipsy(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetClaimableTipsy', value, error)
  }, [value, error]);

  return { claimableTipsy, error, pending };
}

export function useGetMinStakesAmount() {
  const [tier0Stake, setTier0Stake] = useState(undefined);
  const [tier1Stake, setTier1Stake] = useState(undefined);
  const [tier2Stake, setTier2Stake] = useState(undefined);
  const [pending, setPending] = useState(false);
  const argumentsVal = [0, 1, 2];
  const calls =
    argumentsVal?.map((lvlNum) => ({
      contract: tipsyStakeContract,
      method: "UserLevels",
      args: [lvlNum]
    })) ?? [];

  const results = useCalls(calls) ?? [];
  results.forEach((result, idx) => {
    if (result && result.error) {
      setPending(false);
      console.error(
        `Error encountered calling 'UserLevels' on : ${result.error.message}`
      );
    }
  });

  useEffect(() => {
    setPending(true);
    if (results.length > 0 && results?.[0] && results?.[1] && results?.[2]) {
      // results.map(result => {
      setTier0Stake((results[0]?.value?.minimumStaked).add(100));
      setTier1Stake((results[1]?.value?.minimumStaked).add(100));
      setTier2Stake((results[2]?.value?.minimumStaked).add(100));
      // })
      setPending(false);
    }
  }, [results]);

  return { tier0Stake, tier1Stake, tier2Stake };
}

export function useGetLockDuration() {
  const { account } = useEthers();
  const [lockDuration, setUserLockDuration] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && {
        contract: tipsyStakeContract,
        method: "lockDuration",
        args: []
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setUserLockDuration(value?.[0]);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setUserLockDuration(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetLockDuration', value, error)
  }, [value, error]);

  return { lockDuration, error, pending };
}

export function useIsExpiredLockDuration() {
  const { account } = useEthers();
  const [isExpiredLockDuration, setIsExpiredLockDuration] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && {
        contract: tipsyStakeContract,
        method: "getLockDurationOK",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setIsExpiredLockDuration(value?.[0]);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setIsExpiredLockDuration(undefined);
    }
    // console.log('WHAT AM I GETTING: useIsExpiredLockDuration', value, error)
  }, [value, error]);

  return { isExpiredLockDuration, error, pending };
}

export function useGetUserBalance() {
  const { account, chainId } = useEthers();
  const [lastStakedUserBalance, setUserBalance] = useState(undefined);
  const [pending, setPending] = useState(false);

  const { value, error } =
    useCall(
      account && chainId === 56 && {
        contract: tipsyStakeContract,
        method: "getUserBal",
        args: [account]
      }
    ) ?? {};
  useEffect(() => {
    setPending(true);
    if (value) {
      setUserBalance(value?.[0]);
      setPending(false);
    }
    if (error) {
      console.error(error.message);
      setPending(false);
      setUserBalance(undefined);
    }
    // console.log('WHAT AM I GETTING: useGetUserBalance', value, error)
  }, [value, error]);

  return { lastStakedUserBalance, error, pending };
}
