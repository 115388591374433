import React, { useState, useEffect } from "react";
import { BigNumber, Contract, ethers, utils } from "ethers";
import { toast } from "react-toastify";
import {
  ChainId,
  useContractFunction,
  useEtherBalance,
  useEthers,
  Mainnet,
  BSC,
  useTokenBalance
} from "@usedapp/core";
import {
  useGetGinBalance,
  useGetAllowance,
  useGetGoerliGinBalance
} from "../utilities/Web3/tipsystake";
import GinConversionModal from "../components/GinConversionModal";
import {
  GOERLI_GIN_CONTRACT,
  TIPSY_GIN_CONTRACT
} from "../common/environmentVariables";
import TipsyGinAbi from "../abi/TipsyGinAbi.json";
import GoerliGinAbi from "../abi/GoerliGinAbi.json";

const tipsyGinContractAddress = `${TIPSY_GIN_CONTRACT}`;
const tipsyGinContractInterface = new utils.Interface(TipsyGinAbi);
const tipsyGinContract = new Contract(
  tipsyGinContractAddress,
  tipsyGinContractInterface
);

const ethProviderUrl =
  "https://wider-little-pool.quiknode.pro/e6efb29b68088a09f90f8dc0dacf3691834b8036";
// const ethProviderUrl = "https://mainnet.infura.io/v3/ec1a3a8c939e492f8d30453277e4a6fd";

  
const ethProvider = new ethers.providers.JsonRpcProvider(ethProviderUrl);

const ethGinAddress = `${GOERLI_GIN_CONTRACT}`;
const ethGinContractInterface = new utils.Interface(GoerliGinAbi);

// Later in your code where you instantiate the ethGinContract
const ethGinContract = new Contract(
  ethGinAddress,
  ethGinContractInterface,
  ethProvider // use the custom Goerli provider
);


let socket = null;

export default function GinConversion() {
  const { account, deactivate, switchNetwork, chainId } = useEthers();
  const { ginBalance } = useGetGinBalance();
  const { goerliGinBalance } = useGetGoerliGinBalance();
  const [showModal, setShowModal] = useState(false);
  const [inputGin, setInputGin] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { userAllowance } = useGetAllowance();
  const [selectedTab, setSelectedTab] = useState("Conversion");
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    // socket = new WebSocket("wss://tipsyserver-4677c08e7df7.herokuapp.com");
    socket = new WebSocket("wss://3.232.181.243.nip.io");

    socket.addEventListener("open", () => {
      // Request data for the 'getUserTxs' event
      socket.send(`getusertxs ${account}`);
    });

    socket.addEventListener("message", (event) => {
      const messageData = event.data;
      console.log("event.data", messageData);

      // Try parsing the message as JSON
      try {
        const parsedData = JSON.parse(messageData);

        // If the parsed data is an array, process it
        if (Array.isArray(parsedData)) {
          console.log("Received transaction array:", parsedData);
          setTransactions(parsedData);

          // TODO: Update your state with this data or perform other processing here
        }
      } catch (error) {
        // The message wasn't valid JSON or there was another error, just ignore it
        console.log("Received non-JSON message:", messageData);
      }
    });

    // Cleanup the socket connection on component unmount or when 'account' changes
    return () => {
      socket.close();
    };
  }, [account]);

  const tabs = [
    {
      name: "Conversion",
      current: selectedTab === "Conversion",
      onClick: () => setSelectedTab("Conversion")
    },
    {
      name: "Transactions",
      current: selectedTab === "Transactions",
      onClick: () => setSelectedTab("Transactions")
    }
  ];

  const [conversionType, setConversionType] = useState(null);

  // aprove transaction
  const ginConversionApproveHandler = useContractFunction(
    tipsyGinContract,
    "approve",
    {
      transactionName: "Wrap"
    }
  );

  // aprove transaction
  const ginGoerliConversionApproveHandler = useContractFunction(
    ethGinContract,
    "approve",
    {
      transactionName: "Wrap"
    }
  );

  // send transaction instance
  const goerliGinHandler = useContractFunction(ethGinContract, "deposit", {
    transactionName: "Wrap"
  });

  // send transaction instance
  const tipsyGinHandler = useContractFunction(tipsyGinContract, "deposit", {
    transactionName: "Wrap"
  });

  useEffect(() => {
    if (ginConversionApproveHandler.state.status === "PendingSignature") {
      toast.warning("Approval Pending...", {
        autoClose: 20000
      });
    }
    if (ginConversionApproveHandler.state.status === "Success") {
      // setDisabled(false)
      submitGinConversion(utils.parseEther(inputGin));
      toast.success("Approval Confirmed", {
        autoClose: 20000
      });
    }
    if (ginConversionApproveHandler.state.status === "Exception") {
      setDisabled(false);
      toast.error(ginConversionApproveHandler.state.errorMessage, {
        autoClose: 20000
      });
    }
    if (ginConversionApproveHandler.state.status === "Fail") {
      setDisabled(false);
      toast.error(ginConversionApproveHandler.state.errorMessage, {
        autoClose: 20000
      });
    }

    // delete ginConversionApproveHandler.state["status"];
  }, [ginConversionApproveHandler.state]);

  useEffect(() => {
    if (tipsyGinHandler.state.status === "PendingSignature") {
      toast.warning("Deposit in progress... Please wait.", {
        autoClose: 20000
      });
    }
    if (tipsyGinHandler.state.status === "Success") {
      setDisabled(false);

      toast.success("Congratulations! Deposit Successfully.", {
        autoClose: 20000
      });
    }
    if (tipsyGinHandler.state.status === "Exception") {
      setDisabled(false);
      toast.error(tipsyGinHandler.state.errorMessage, { autoClose: 20000 });
    }
    if (tipsyGinHandler.state.status === "Fail") {
      setDisabled(false);
      toast.error(tipsyGinHandler.state.errorMessage, { autoClose: 20000 });
    }

    // delete tipsyGinHandler.state["status"];
  }, [tipsyGinHandler.state]);

  useEffect(() => {
    if (ginGoerliConversionApproveHandler.state.status === "PendingSignature") {
      toast.warning("Approval Pending...", {
        autoClose: 20000
      });
    }
    if (ginGoerliConversionApproveHandler.state.status === "Success") {
      // setDisabled(false)
      submitGoerliGinConversion(utils.parseEther(inputGin));
      toast.success("Approval Confirmed", {
        autoClose: 20000
      });
    }
    if (ginGoerliConversionApproveHandler.state.status === "Exception") {
      setDisabled(false);
      toast.error(ginGoerliConversionApproveHandler.state.errorMessage, {
        autoClose: 20000
      });
    }
    if (ginGoerliConversionApproveHandler.state.status === "Fail") {
      setDisabled(false);
      toast.error(ginGoerliConversionApproveHandler.state.errorMessage, {
        autoClose: 20000
      });
    }
    // delete ginGoerliConversionApproveHandler.state["status"];
  }, [ginGoerliConversionApproveHandler.state]);

  useEffect(() => {
    if (goerliGinHandler.state.status === "PendingSignature") {
      toast.warning("Deposit in progress... Please wait.", {
        autoClose: 20000
      });
    }
    if (goerliGinHandler.state.status === "Success") {
      switchNetwork(56);
      setDisabled(false);

      toast.success("Congratulations! Deposit Successfully.", {
        autoClose: 20000
      });
    }
    if (goerliGinHandler.state.status === "Exception") {
      setDisabled(false);
      toast.error(goerliGinHandler.state.errorMessage, { autoClose: 20000 });
    }
    if (goerliGinHandler.state.status === "Fail") {
      setDisabled(false);
      toast.error(goerliGinHandler.state.errorMessage, { autoClose: 20000 });
    }

    // delete goerliGinHandler.state["status"];
  }, [goerliGinHandler.state]);

  const disconnectWallet = () => {
    setConversionType(null);
    setShowModal(false);
    deactivate();
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("shouldConnectMetamask");
  };

  const openConversionModal = (type) => {
    setConversionType(type);
    setShowModal(true);
  };

  const submitGinConversion = (value) => {
    console.log(
      "submitGinConversion => submit handler.................",
      value
    );
    // console.log("bridgeFee", utils.parseEther("0.1").toString());
    // console.log("input amount", value.toString());
    // console.log("overrides", utils.parseEther("0.1").toString());

    const overrides = {
      value: utils.parseEther("0.1") // This is the additional ETH to send with the transaction
    };

    try {
      tipsyGinHandler.send(value, 1, overrides);
    } catch (error) {
      setDisabled(false);
      toast.error(error?.message);
      console.log("error", error);
    }
  };

  const submitGoerliGinConversion = async (value) => {
    console.log(
      "submitGoerliGinConversion => submit handler.................",
      value
    );
    if (ChainId.Mainnet !== chainId) {
      await switchNetwork(ChainId.Mainnet);
    }

    const overrides = {
      value: utils.parseEther("0.1") // This is the additional ETH to send with the transaction
    };

    // console.log("There are the logs");
    // console.log("bridgeFee", utils.parseEther("0.1"));
    // console.log("amount", value);

    try {
      goerliGinHandler.send(value, 56).then((res) => {
        setDisabled(false);
      }); // bscmainnet chain id 56
    } catch (error) {
      setDisabled(false);
      toast.error(error?.message);
      console.log("error", error);
    }
  };

  const conversionApproveHandler = async (value) => {
    // aprove first  and then do the stake
    // console.log("input value", value);
    // console.log("conversionType", conversionType);
    setInputGin(value);
    setDisabled(true);
    try {
      if (conversionType === "bte") {
        if (ChainId.BSC !== chainId) {
          await switchNetwork(ChainId.BSC);
        }
        ginConversionApproveHandler.send(account, utils.parseEther(value));
      }
      if (conversionType === "etb") {
        if (ChainId.Mainnet !== chainId) {
          await switchNetwork(ChainId.Mainnet);
        }
        ginGoerliConversionApproveHandler
          .send(account, utils.parseEther(value))
          .then((res) => {
            console.log("ressss", res);
          });
      }
    } catch (error) {
      setDisabled(false);
      console.log("error", error.message);
    }
  };

  // const approveToken = async () => {
  //   // aprove first  and then do the stake
  //   setDisabled(true)

  //   ginConversionApproveHandler
  //     .send(account, MaxUint256.toString())
  //     .then((data) => {
  //       if (data.status) {
  //         setDisabled(false)
  //       }
  //     })
  //     .catch((e) => {
  //       setDisabled(false)
  //       toast.error(e?.message)
  //       console.log('error', e?.message)
  //     })
  // }

  return (
    <>
      <div className="2xl:px-52 xl:px-28 lg:px-20 px-2 py-20 text-[#EEFFFD] font-sans">
        <div className="mb-10 flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:items-center sm:justify-between">
          <div className="flex flex-col">
            <h2 className="md:text-4xl text-3xl uppercase lg:mb-0 mb-4">
              Convert GIN (ERC20 &lt;&gt; BEP20)
            </h2>
            <p className="text-xs text-[#EEFFFD] mt-2">
              Exchange your Gin tokens ($gin) easily on the blockchain, between
              the BNB Chain and Ethereum Mainnet. <br /> To use your
              Gin in Gate of Abyss, please do so{" "}
              <a
                className="underline underline-offset-2 cursor-pointer text-[#F631A7]"
                href="https://app.gateofabyss.com/"
                target="_blank"
              >
                here
              </a>{" "}
              and refer to {" "}
              <a
                className="underline underline-offset-2 cursor-pointer text-[#F631A7]"
                href="https://wiki.gateofabyss.com/gateofabyss/web-3-connectivity/obtaining-gin/convert-blockchain-gin-to-in-game"
                target="_blank"
              >
                
                our Wiki
              </a>
            </p>
          </div>

          <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:items-center sm:justify-between sm:space-x-2">
            {account && (
              <button
                type="button"
                onClick={() => disconnectWallet()}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Disconnect WALLET
              </button>
            )}
          </div>
        </div>

        <div className="my-6">
          <nav className="-mb-px flex space-x-6" aria-label="Tabs">
            {tabs.map((tab) => (
              <p
                key={tab.name}
                onClick={tab.onClick}
                className={`${
                  tab.current
                    ? "border-white text-white"
                    : "border-transparent text-white hover:text-[#F2DB05]"
                } whitespace-nowrap cursor-pointer border-b-2 pb-2  text-sm font-normal font-inter focus:outline-none`}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </p>
            ))}
          </nav>
        </div>

        {selectedTab === "Conversion" && (
          <div className="flex xl:flex-row flex-col xl:space-x-8 xl:space-y-0 space-y-4 justify-between">
            <div className="xl:w-1/2 w-full border border-solid border-[#626262] bg-[#292929] rounded-[36px] p-10">
              <p className="uppercase text-[#F2DB05] md:text-xl text-lg mb-4">
                Mainnet (ERC20)
              </p>
              <hr className="border border-[#707070]" />

              <div className="flex flex-col mt-6 uppercase">
                {chainId === 1 ? (
                  <>
                    <p className="uppercase text-xs text-[#EEFFFD]">
                      Your wallet
                    </p>
                    {account && goerliGinBalance !== undefined ? (
                      <p className="text-[#F2DB05] md:text-2xl tracking-wider font-bold text-lg">
                        {goerliGinBalance.toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        })}{" "}
                        $GIN
                      </p>
                    ) : (
                      <p className="text-[#F2DB05] md:text-2xl tracking-wider font-bold text-lg">
                        0 $GIN
                      </p>
                    )}
                  </>
                ) : (
                  <div className="grid grid-cols-2 gap-10">
                    <p className="text-[#F2DB05] tracking-wider font-normal text-sm">
                      Please switch to the Mainnet.
                    </p>

                    <button
                      type="button"
                      onClick={() => switchNetwork(1)}
                      className={`border-[#F2DB05] text-[#F2DB05] hover:bg-[#31CC62] hover:text-white border rounded-[27px] text-sm px-2 py-2 text-center uppercase w-auto`}
                    >
                      Switch Network
                    </button>
                  </div>
                )}
              </div>

              <button
                type="button"
                disabled={!account || disabled || chainId !== 1}
                onClick={() => openConversionModal("etb")}
                className={`${
                  !account || disabled || chainId !== 1
                    ? "border-[#828282] text-[#828282]"
                    : "border-[#F2DB05] text-[#F2DB05]"
                } border rounded-[27px] text-sm px-10 py-2 text-center uppercase mt-10 w-full`}
              >
                Convert to BEP20
              </button>
            </div>

            <div className="xl:w-1/2 w-full border border-solid border-[#626262] bg-[#292929] rounded-[36px] p-10">
              <p className="uppercase text-[#31CB62] md:text-xl text-lg mb-4">
                BNB Chain (BEP20)
              </p>
              <hr className="border border-[#707070]" />

              <div className="flex flex-col mt-6 uppercase">
                {chainId === 56 ? (
                  <>
                    <p className="uppercase text-xs text-[#EEFFFD]">
                      Your wallet
                    </p>
                    {account && ginBalance !== undefined ? (
                      <p className="text-[#31CB62] md:text-2xl tracking-wider font-bold text-lg">
                        {ginBalance.toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        })}{" "}
                        $GIN
                      </p>
                    ) : (
                      <p className="text-[#31CB62] md:text-2xl tracking-wider font-bold text-lg">
                        0 $GIN
                      </p>
                    )}
                  </>
                ) : (
                  <div className="grid grid-cols-2 gap-10">
                    <p className="text-[#31CB62] tracking-wider font-normal text-sm">
                      Please switch to the BNB Chain.
                    </p>

                    <button
                      type="button"
                      onClick={() => switchNetwork(56)}
                      className={`border-[#31CB62] text-[#31CB62] hover:bg-[#31CC62] hover:text-white border rounded-[27px] text-sm px-2 text-center uppercase w-auto`}
                    >
                      Switch Network
                    </button>
                  </div>
                )}
              </div>

              <button
                type="button"
                disabled={!account || disabled || chainId !== 56}
                onClick={() => openConversionModal("bte")}
                className={`${
                  !account || disabled || chainId !== 56
                    ? "border-[#828282] text-[#828282]"
                    : "border-[#F2DB05] text-[#F2DB05]"
                } border rounded-[27px] text-sm px-10 py-2 text-center uppercase mt-10 w-full`}
              >
                Convert to ERC20
              </button>
            </div>
          </div>
        )}

        {selectedTab === "Transactions" && (
          <div className="">
            <div className="border-t border-b">
              <div className="inline-block min-w-full py-0 align-middle sm:px-0 lg:px-0">
                <table className="min-w-full divide-y divide-black/[.16]-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-normal text-[#F2DB05] sm:pl-0"
                      >
                        <span className="border-b border-dashed border-[#F2DB05] hover:cursor-pointer">
                          Txn Hash
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-normal text-[#F2DB05] sm:pl-0"
                      >
                        <span className="border-b border-dashed border-[#F2DB05] hover:cursor-pointer">
                          Amount
                        </span>
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-normal text-[#F2DB05] sm:pl-0"
                      >
                        <span className="border-b border-dashed border-[#F2DB05] hover:cursor-pointer">
                          Timestamp
                        </span>
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-normal text-[#F2DB05] sm:pl-0"
                      >
                        <span className="border-b border-dashed border-[#F2DB05] hover:cursor-pointer">
                          Status
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {transactions.map((tx) => (
                      <tr
                        key={tx.txHash}
                        className="hover:bg-[#00000014] hover:cursor-pointer"
                      >
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-left text-gray-900 sm:pl-0">
                          <div className="font-bold text-sm text-white">
                            {tx.txHash.slice(0, 10)}...{tx.txHash.slice(-10)}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-left text-gray-900 sm:pl-0">
                          <div className="font-bold text-sm text-white">
                            {utils.formatEther(tx.Details.Amount)} $GIN
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-left text-gray-900 sm:pl-0">
                          <div className="font-bold text-sm text-white">
                            {new Date(
                              Number(tx.Details.Timestamp) * 1000
                            ).toLocaleString()}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-left text-gray-900 sm:pl-0">
                          <div className="font-bold text-sm text-white">
                            {tx.Details.Status === 2
                              ? "Completed"
                              : tx.Details.Status === 3
                              ? "Error"
                              : ""}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {transactions.length === 0 && (
              <p className="text-center my-4 text-lg text-[#EEFFFD]">
                No transaction found.
              </p>
            )}
          </div>
        )}
      </div>
      {showModal && (
        <GinConversionModal
          userAllowance={userAllowance}
          ginBalance={ginBalance}
          onAction={conversionApproveHandler}
          type={conversionType}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
