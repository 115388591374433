import React from 'react'
import WarningSignImg from '../assets/images/warningSign.svg'
const UnstakeConfirmModal = (props) => {
  return (
    <>
      <div className="flex justify-center items-center font-roboto overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative lg:w-[23%] sm:w-2/3 w-[90%] my-6 mx-auto">
          <div className="bg-[#1B1B1B] border border-[#626262] rounded-[36px] tipsy-shadow relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-6 flex-auto">
              <h3 className="text-[#EEFFFD] text-sm">CONFIRM UNSTAKING</h3>
              <hr className="my-4 border-[#707070]" />
              <p className="text-[#EEFFFD] text-xs ">
                <img src={WarningSignImg} alt="" className="w-4 h-4 mr-2 inline-block" />
                Once you unstake $tipsy you will stop earning $gin rewards, and re-staking will reset the 90 day lock. <br/>
                Unstaking will automatically harvest your rewards, and mint any allocated $gin to your BSC staking wallet.
              </p>
              <div>
                <button
                  type="button"
                  onClick={props.onAction}
                  className="border-[#F631A7] border rounded-[27px] text-sm px-6 py-1 mr-3 mt-4 text-center text-[#F631A7] uppercase"
                >
                  Proceed
                </button>
                <button
                  onClick={props.onHide}
                  type="button"
                  className="border-[#EEFFFD] border rounded-[27px] text-sm px-6 py-1 mt-4 text-center text-[#EEFFFD] uppercase"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnstakeConfirmModal
