import React, { useState } from 'react'
const GinConversionModal = ({ type, userAllowance, onAction, onHide, ginBalance }) => {
  const [inputGin, setInputGin] = useState('')
  const setMaximum = () => {
    console.log('maximum........',ginBalance.toLocaleString(undefined, {maximumFractionDigits: 0}))
    setInputGin(ginBalance)
  }
  const inputGinHandler = () => {
    if(inputGin && inputGin > 0) {
      onAction(inputGin)
      onHide();
    }
  }
  const setInputGinHandler = (event) => {
    setInputGin(event.target.value)
  }
  return (
    <>
      <div className="flex justify-center items-center font-roboto overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative lg:w-[23%] sm:w-2/3 w-[90%] my-6 mx-auto">
          <div className="bg-[#1B1B1B] border border-[#626262] rounded-[36px] tipsy-shadow relative flex flex-col w-full outline-none focus:outline-none">
            <div className="relative p-6 flex-auto">
              <h3 className="text-[#0597F2] text-lg">How much $GIN would you like to convert? <br />
                {type === 'bte' ? '(BEP20 to ERC20)' : '(ERC20 to BEP20)'}
              </h3>

              <div className="flex flex-col">
                <p className="text-right text-white text-sm underline underline-offset-2 mb-1 cursor-pointer" onClick={() => setMaximum()}>Maximum</p>
                <input
                  type="number"
                  className="border border-[#626262] text-white text-lg text-right rounded-[10px] bg-[#000000] py-2 px-2"
                  value={inputGin}
                  onChange={setInputGinHandler}
                />
              </div>

              <div className="flex items-center justify-between space-x-2">
                <button
                  type="button"
                  onClick={inputGinHandler}
                  className="border-[#F631A7] w-1/2 border rounded-[27px] text-sm px-6 py-1 mr-3 mt-4 text-center text-[#F631A7] uppercase"
                >
                  Convert to {type === 'bte' ? 'ERC20' : 'BEP20'}
                </button>
                <button
                  onClick={onHide}
                  type="button"
                  className="border-[#EEFFFD] w-1/2 border rounded-[27px] text-sm px-6 py-1 mt-4 text-center text-[#EEFFFD] uppercase"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GinConversionModal
