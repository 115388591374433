import React, { useEffect, useState } from "react";
import { useEthers, useContractFunction } from "@usedapp/core";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { MaxUint256 } from "@ethersproject/constants";

import {
  useBalanceOf,
  useGetAllowance,
  useBalanceOfStakingContract,
} from "../utilities/Web3/tipsycoin";
import {
  useGetUserInfo,
  useGetTotalWeight,
  useGetLockDuration,
  useGetAllocatedGin,
  useGetClaimableTipsy,
  useGetMinStakesAmount,
  useGetUserLvlTxt_Cached,
  useIsExpiredLockDuration,
  useGetUserBalance,
  useGetGinBalance,
} from "../utilities/Web3/tipsystake";
import { useGetTipsyPriceInUSD } from "../utilities/Web3/getExchangeRate";

import { SpinnerCircular } from "spinners-react";
import ConnectWalletModal from "../components/connectWalletModal";
import StakeEnableModal from "../components/StakeEnableModal";
import StakeConfirmModal from "../components/StakeConfirmModal";
import UnstakeConfirmModal from "../components/UnstakeConfirmModal";
import MintTracker from "../components/MintTracker"; // Timer Component

import TipsyStakeAbi from "../abi/TipsyStakeAbi.json";
import TipsyCoinAbi from "../abi/TipsyCoinAbi.json";
import {
  TIPSY_COIN_CONTRACT,
  TIPSY_STAKE_CONTRACT,
} from "../common/environmentVariables";
import { Contract, utils } from "ethers";

// tipsy contract instance to send transaction
const tipsyStakeContractAddress = `${TIPSY_STAKE_CONTRACT}`;
const tipsyStakeContractInterface = new utils.Interface(TipsyStakeAbi);
const tipsyStakeContract = new Contract(
  tipsyStakeContractAddress,
  tipsyStakeContractInterface
);

// tipsy coin contract instance to send transaction
const tipsyCoinContractAddress = `${TIPSY_COIN_CONTRACT}`;
const tipsyCoinContractInterface = new utils.Interface(TipsyCoinAbi);
const tipsyCoinContract = new Contract(
  tipsyCoinContractAddress,
  tipsyCoinContractInterface
);

export default function LogsPage() {
  const { account, deactivate } = useEthers();
  const { balanceOf } = useBalanceOf();
  const { userInfoMap } = useGetUserInfo();
  const { totalWeight } = useGetTotalWeight();
  const { claimableTipsy } = useGetClaimableTipsy();
  const [showModal, setShowModal] = useState(false);
  const { balanceOfStakingContract } = useBalanceOfStakingContract();
  const { tier0Stake, tier1Stake, tier2Stake } = useGetMinStakesAmount();
  const { allocatedGin } = useGetAllocatedGin();
  const { ginBalance } = useGetGinBalance();
  const { userLvlTxt } = useGetUserLvlTxt_Cached();
  const { lockDuration } = useGetLockDuration();
  const { isExpiredLockDuration } = useIsExpiredLockDuration();
  const { lastStakedUserBalance } = useGetUserBalance();
  const { userAllowance } = useGetAllowance();
  const { tipsyToUsdt } = useGetTipsyPriceInUSD();
  console.log(`tipsyToUsdt: ${tipsyToUsdt}`);

  console.log(
    `%cTier I ${tier0Stake} || Tier II ${tier1Stake} || Tier III ${tier2Stake}`,
    "color:green"
  );
  console.log(
    "================================================================================================================"
  );
  console.log(
    `%cWallet: ${account} || userAllowance: ${userAllowance} || isExpiredLockDuration: ${isExpiredLockDuration} || LockDuration: ${lockDuration} || Remaining Balance: ${balanceOf} || TipsyStakeContract Balance: ${balanceOfStakingContract} || Claimableable Tipsy: ${claimableTipsy} || UserLevel: ${userLvlTxt} || UserAllocatedGin: ${allocatedGin} || lastStakedUserBalance: ${lastStakedUserBalance}`,
    "color: green"
  );
  console.log(
    "================================================================================================================"
  );

  console.log(
    `%c LastAction ${userInfoMap?.lastAction.toNumber()} || LastRewardBlock ${
      userInfoMap?.lastRewardBlock
    } || LastWeight ${
      userInfoMap?.lastWeight
    } || TotalWeight ${totalWeight} || RewardDebt ${
      userInfoMap?.rewardDebt
    } || RewardEarnedNotMinted ${
      userInfoMap?.rewardEarnedNotMinted
    } || UserLevel ${userInfoMap?.userLevel} || UserMulti ${
      userInfoMap?.userMulti
    }`,
    "color:blue"
  );
  console.log(
    "================================================================================================================"
  );

  const disconnectWallet = () => {
    deactivate();
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("shouldConnectMetamask");
  };

  function buyTipsy(url) {
    window.open(url, "_blank");
  }
  function displayAccount() {
    if (account !== undefined && account) {
      return (
        <span>{account.substring(0,8)}...{account.substring(36,42)}</span>
      )
    } else {
    }
  }

  return (
    <>
      <div className="2xl:px-52 xl:px-28 lg:px-20 px-2 py-20 text-[#EEFFFD] font-sans">
        {/* Connect Wallet Button */}
        <div className="mb-10 flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:items-center sm:justify-between">
          <div>
            <h2 className="md:text-4xl text-3xl uppercase lg:mb-0 mb-4">
              STAKE TIPSYCOIN
            </h2>
            {!account && (
              <p className="text-xs mt-2">
                Please connect your wallet to stake $tipsy.
              </p>
            )}
          </div>
          <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:items-center sm:justify-between sm:space-x-2">
            <button
              type="button"
              onClick={() =>
                buyTipsy(
                  "https://pancakeswap.finance/swap?outputCurrency=0xe097bcEb09bfb18047Cf259F321cC129b7bEba5e"
                )
              }
              className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
            >
              BUY $TIPSY
            </button>
            {!account ? (
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Connect WALLET
              </button>
            ) : (
              <button
                type="button"
                onClick={() => disconnectWallet()}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Disconnect WALLET
              </button>
            )}
          </div>
        </div>

        <div className="flex flex-col">
          {/* <div className="flex items-center justify-between">
            <p>tipsyToUsdt</p>
            <p>{tipsyToUsdt}</p>
          </div>
          <hr /> */}
          <div className="flex items-center justify-between py-2 px-2">
            <p>account</p>
            <p>{account !== undefined ? displayAccount() : "**"}</p>
          </div>
          <hr />
          <div className="flex items-center justify-between py-2 px-2">
            <p>ginbalance</p>
            <p>{ginBalance ? ginBalance : "**"}</p>
          </div>
          <hr />
          <div className="flex items-center justify-between py-2 px-2">
            <p>tier0Stake</p>
            <p>{tier0Stake ? tier0Stake / 1e18 : "**"}</p>
          </div>
          <hr />
          <div className="flex items-center justify-between py-2 px-2">
            <p>Level</p>
            <p>{userLvlTxt !== undefined ? userLvlTxt : "**"}</p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>tier1Stake</p>
            <p>{tier1Stake ? tier1Stake / 1e18 : "**"}</p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>tier2Stake</p>
            <p>{tier2Stake ? tier2Stake / 1e18 : "**"}</p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>userAllowance</p>
            <p>{userAllowance !== undefined ? userAllowance : "**"}</p>
          </div>
          <hr />

          {/* <div className="flex items-center justify-between py-2 px-2">
            <p>isExpiredLockDuration</p>
            <p>{isExpiredLockDuration ? 1: 0}</p>
          </div>
          <hr /> */}

          {/* <div className="flex items-center justify-between py-2 px-2">
            <p>lockDuration</p>
            <p>{lockDuration ? lockDuration.toNumber(): '--'}</p>
          </div>
          <hr /> */}

          <div className="flex items-center justify-between py-2 px-2">
            <p>balanceOf</p>
            <p>{balanceOf !== undefined ? balanceOf / 1e18 : "**"}</p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>balanceOfStakingContract</p>
            <p>
              {balanceOfStakingContract !== undefined
                ? balanceOfStakingContract / 1e18
                : "**"}
            </p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>claimableTipsy</p>
            <p>{claimableTipsy !== undefined ? claimableTipsy / 1e18 : "**"}</p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>allocatedGin</p>
            <p>{allocatedGin !== undefined ? allocatedGin / 1e18 : "**"}</p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>lastStakedUserBalance</p>
            <p>
              {lastStakedUserBalance !== undefined
                ? Math.round(lastStakedUserBalance / 1e18)
                : "**"}
            </p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>userLevel</p>
            <p>
              {userInfoMap !== undefined ? userInfoMap?.userLevel : "**"}
            </p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>lastAction</p>
            <p>
              {userInfoMap !== undefined
                ? userInfoMap?.lastAction / 1e18
                : "**"}
            </p>
          </div>
          <hr />

          <div className="flex items-center justify-between py-2 px-2">
            <p>lastWeight</p>
            <p>
              {userInfoMap !== undefined
                ? userInfoMap?.lastWeight / 1e18
                : "**"}
            </p>
          </div>
          <hr />
        </div>
      </div>
      {showModal && <ConnectWalletModal onHide={() => setShowModal(false)} />}
    </>
  );
}
