import React, { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { io } from "socket.io-client";

import {
  useBalanceOf,
  useBalanceOfStakingContract,
  useTotalSupply
} from "../utilities/Web3/tipsycoin";
import {
  useGetUserInfo,
  useGetTotalWeight,
  useGetGinBalance,
  useGetAllocatedGin,
  useGetUserBalance,
  useGetGoerliGinBalance
} from "../utilities/Web3/tipsystake";

import { useGetTipsyPriceInUSD } from "../utilities/Web3/getExchangeRate";
import ConnectWalletModal from "../components/connectWalletModal";
import MinecraftModal from "../components/minecraftModal";

let socket = null;

export default function DashboardPage() {
  const { account, deactivate, chainId, switchNetwork } = useEthers();
  const { balanceOf } = useBalanceOf();
  const { userInfoMap } = useGetUserInfo();
  const { totalWeight } = useGetTotalWeight();
  const { goerliGinBalance } = useGetGoerliGinBalance();

  const { allocatedGin } = useGetAllocatedGin();
  const { ginBalance } = useGetGinBalance();
  const { totalSupply } = useTotalSupply();
  const [showModal, setShowModal] = useState(false);
  const [isMinecraftModal, showMinecraftModal] = useState(false);
  const { balanceOfStakingContract } = useBalanceOfStakingContract();
  const [isLinkedMinecraftAccount, setLinkedMinecraftAccount] = useState(false);
  const { lastStakedUserBalance } = useGetUserBalance();
  const [currentUUID, setUUID] = useState(null);
  const { tipsyToUsdt } = useGetTipsyPriceInUSD();
  // console.log(`tipsyToUsdt: ${tipsyToUsdt}`)
  // console.log(`ginBalance: ${ginBalance}`)
  // console.log("balanceOfStakingContract", balanceOfStakingContract);

  // useEffect(() => {
  //   if (account !== undefined && account) {
  //     socket = io("https://server.tipsycoin.io/", {
  //       transports: ["websocket"],
  //     });
  //     socket.on("connect", () => {
  //       const username = "testFrontend";
  //       socket.emit("user_join", { name: username });
  //       socket.emit("getUUID", { walletAddress: account }, (response) => {
  //         setUUID(response.uuid);
  //       });
  //     });
  //   }
  // }, [account]);

  // function displayUUID() {
  //   if (currentUUID !== null && currentUUID !== -1 && currentUUID) {
  //     return (
  //       <span>
  //         {currentUUID.substring(0, 10)}...{currentUUID.substring(25, 32)}
  //       </span>
  //     );
  //   }
  // }

  const disconnectWallet = () => {
    deactivate();
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("shouldConnectMetamask");
  };

  function buyTipsy(url) {
    window.open(url, "_blank");
  }

  return (
    <>
      <div className="2xl:px-52 xl:px-28 lg:px-20 px-2 py-20 text-[#EEFFFD] font-sans">
        {/* Connect Wallet Button xl:flex-row flex-col xl:space-y-0 space-y-4*/}
        <div className="mb-10 flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:items-center sm:justify-between">
          <h2 className="md:text-4xl text-3xl uppercase lg:mb-0 mb-4">
            Dashboard
          </h2>
          <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:items-center sm:justify-between sm:space-x-2">
            <button
              type="button"
              onClick={() =>
                buyTipsy(
                  "https://pancakeswap.finance/swap?outputCurrency=0xe097bcEb09bfb18047Cf259F321cC129b7bEba5e"
                )
              }
              className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
            >
              BUY $TIPSY
            </button>

            {account && (
              <button
                type="button"
                onClick={() => disconnectWallet()}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Disconnect WALLET
              </button>
            )}
          </div>
        </div>

        <div className="">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
            <div className="border border-solid border-[#626262] bg-[#292929] rounded-[36px] px-6 py-4">
              <div className="flex items-center justify-between mb-3">
                <p className="uppercase text-sm">Your wallet</p>
                {/* {account && (
                  <button
                    type="button"
                    className="border-[#F631A7] border rounded-[27px] text-sm px-6 py-2 mt-4 text-center text-[#F631A7] uppercase"
                  >
                    Change WALLET
                  </button>
                )} */}
              </div>
              <hr className="border border-[#707070]" />
              {account !== undefined && account && (
                <p className="text-xl text-[#F631A7] mt-4">
                  {account.substring(0, 10)}...{account.substring(36, 42)}
                </p>
              )}
              {/* {account !== undefined && account && currentUUID !== null && currentUUID !== -1 && currentUUID && (
                <p className="text-xl text-[#F631A7] mt-4">
                  <span className="text-lg">Minecraft ID:</span><br/>
                  {displayUUID()}
                </p>
              )} */}
              {!account && (
                <button
                  type="button"
                  className="border-[#F631A7] border hover:bg-[#F631A7] rounded-[27px] text-sm xl:w-3/5 md:w-2/5 sm:w-3/5 w-full sm:px-6 px-2 py-2 mt-4 mb-1 text-center text-white hover:text-[#EEFFFD] uppercase"
                  onClick={() => setShowModal(true)}
                >
                  Connect WALLET
                </button>
              )}
              {/* {isLinkedMinecraftAccount && (
                <p className="text-xs text-[#03F149] cursor-pointer mb-4">
                  Linked to microsoft account
                </p>
              )}
              {!isLinkedMinecraftAccount && (
                <>
                  <p className="text-xs text-[#FF1B1B] cursor-pointer">
                    Wallet is not linked to any Minecraft account
                  </p>
                  <p
                    className="text-xs text-[#FFFFFF] cursor-pointer underline"
                    onClick={() => showMinecraftModal(true)}
                  >
                    Login to Minecraft
                  </p>
                </>
              )} */}
            </div>

            <div className="border border-solid border-[#626262] bg-[#292929] rounded-[36px] px-6 py-4">
              <div className="flex items-center justify-between mb-3">
                <p className="uppercase text-sm">Total Tipsycoin Supply</p>
                <p className="text-[#F2DB05] md:text-xl text-lg">
                  {totalSupply !== undefined &&
                    totalSupply.toLocaleString(undefined, {
                      maximumFractionDigits: 0
                    })}{" "}
                  $tipsy
                </p>
              </div>

              <hr className="border border-[#707070] my-2" />

              <div className="flex items-center justify-between">
                <p className="text-xs text-[#EEFFFD] uppercase">
                  Total $Tipsy Staked
                  <br />
                  {balanceOfStakingContract !== undefined ? (
                    <span className="text-[#F2DB05] md:text-xl text-lg">
                      {Math.round(balanceOfStakingContract).toLocaleString()}{" "}
                      $TIPSY
                    </span>
                  ) : (
                    <span className="text-[#F2DB05] md:text-xl text-lg">
                      0 $Tipsy
                    </span>
                  )}
                </p>

                <div className="text-xs text-[#EEFFFD] uppercase">
                  % of $Tipsy Staked
                  <br />
                  {totalSupply !== undefined &&
                  balanceOfStakingContract !== undefined ? (
                    <p className="text-[#F2DB05] md:text-xl text-lg text-right">
                      {(
                        (balanceOfStakingContract / totalSupply) *
                        100
                      ).toLocaleString()}{" "}
                      %
                    </p>
                  ) : (
                    <p className="text-[#F2DB05] md:text-xl text-lg text-right">
                      0%
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="border border-solid border-[#626262] bg-[#292929] rounded-[36px] px-6 py-4">
              <div className="flex items-center justify-between mb-3">
                <p className="uppercase text-sm">$GIN BALANCE</p>
                <button
                  type="button"
                  disabled={true}
                  className={`${
                    true
                      ? "border-[#828282] text-[#828282]"
                      : "border-[#F2DB05] text-[#F2DB05]"
                  } border rounded-[27px] text-sm px-10 py-2 text-center uppercase`}
                >
                  Claim
                </button>
              </div>
              <hr className="border border-[#707070]" />

              <div className="flex items-start md:space-x-6 space-x-2 justify-between mt-3 uppercase">
                {account && ginBalance !== undefined ? (
                  <p className="text-xs text-center text-[#EEFFFD]">
                    BNB Chain <br />
                    {chainId === 56 ? (
                      <span className="text-[#F2DB05] md:text-xl text-lg">
                        {ginBalance.toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        })}
                      </span>
                    ) : (
                      <button
                        type="button"
                        onClick={() => switchNetwork(56)}
                        className={`text-[#31CC62] border border-[#31CC62] hover:bg-[#31CC62] hover:text-white rounded-2xl text-[10px] mt-1 py-1 px-3 text-center uppercase w-[120px]`}
                      >
                        Switch Network
                      </button>
                    )}
                  </p>
                ) : (
                  <p className="text-xs text-[#EEFFFD]">
                    BNB Chain <br />
                    <span className="text-[#F2DB05] md:text-xl text-lg">0</span>
                  </p>
                )}
                {/* <p className="text-xs text-[#EEFFFD]">
                  Polygon <br />
                  <span className="text-[#F2DB05] md:text-xl text-lg">0</span>
                </p> */}

                <div className="text-xs text-center text-[#EEFFFD]">
                  <p>ETH Mainnet</p>
                  {chainId === 1 ? (
                    <>
                      <span className="text-[#F2DB05] md:text-xl text-lg">
                        {goerliGinBalance &&
                          goerliGinBalance.toLocaleString(undefined, {
                            maximumFractionDigits: 0
                          })}
                      </span>
                    </>
                  ) : (
                      <button
                        type="button"
                        onClick={() => switchNetwork(1)}
                        className={`text-[#31CC62] border border-[#31CC62] hover:bg-[#31CC62] hover:text-white rounded-2xl text-[10px] mt-1 py-1 px-3 text-center uppercase w-[120px]`}
                      >
                        Switch Network
                      </button>
                  )}
                </div>
                <p className="h-10 border border-[#707070]"></p>
                <p className="text-xs text-center text-[#EEFFFD]">
                  Tipsy Server <br />
                  <span className="text-[#F2DB05] md:text-xl text-lg">0</span>
                </p>
              </div>

              <hr className="border border-[#707070] my-2" />
              {account && allocatedGin !== undefined && allocatedGin > 0 && (
                <>
                  <p className="text-xs text-[#EEFFFD] uppercase">
                    Total Reserved
                    <br />
                    <span className="text-[#F2DB05] md:text-xl text-lg">
                      {allocatedGin.toLocaleString(undefined, {
                        maximumFractionDigits: 2
                      })}{" "}
                      $GIN
                    </span>
                  </p>
                  <p className="text-xs text-[#EEFFFD]">
                    Gin is now live! Your $gin will be minted the next time you
                    stake, unstake, or harvest
                  </p>
                </>
              )}
              {account && ginBalance !== undefined && (
                <>
                  <p className="text-xs text-[#EEFFFD] uppercase">
                    Total Gin Balance
                    <br />
                    {chainId === 1 && (
                      <span className="text-[#F2DB05] md:text-xl text-lg">
                        {ginBalance.toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })}{" "}
                        $GIN
                      </span>
                    )}
                    {chainId === 56 && (
                      <span className="text-[#F2DB05] md:text-xl text-lg">
                        {goerliGinBalance.toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        })}{" "}
                        $GIN
                      </span>
                    )}
                  </p>
                </>
              )}
            </div>

            <div className="border border-solid border-[#626262] bg-[#292929] rounded-[36px] px-6 py-4">
              <div className="flex items-center justify-between mb-3">
                <p className="uppercase text-sm">$TIPSY BALANCE</p>
              </div>
              <hr className="border border-[#707070]" />

              <div className="mt-4 flex justify-between uppercase">
                {account && balanceOf !== undefined ? (
                  <p className="text-xs text-[#EEFFFD]">
                    Available <br />
                    <span className="sm:text-2xl text-sm  text-[#0597F2]">
                      {Math.round(balanceOf / 1e18).toLocaleString()} $TIPSY
                    </span>
                    <br />
                    {Math.round(balanceOf / 1e18) > 0 && (
                      <span className="text-sm text-[#F2DB05]">
                        US$
                        {(
                          tipsyToUsdt * Math.round(balanceOf / 1e18)
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })}
                      </span>
                    )}
                  </p>
                ) : (
                  <p className="flex flex-col text-xs text-[#EEFFFD]">
                    Available <br />
                    <span className="sm:text-2xl text-sm  text-[#0597F2]">
                      0 $TIPSY
                    </span>
                  </p>
                )}
                <div className="text-xs text-[#EEFFFD] pl-[15px]">
                  Staked <br />
                  {account && lastStakedUserBalance !== undefined ? (
                    <>
                      <span className="sm:text-2xl text-sm  text-[#0597F2]">
                        {Math.round(
                          lastStakedUserBalance / 1e18
                        ).toLocaleString()}
                        &nbsp;$TIPSY
                      </span>
                      <br />
                      {Math.round(lastStakedUserBalance / 1e18) > 0 && (
                        <span className="text-sm text-[#F2DB05]">
                          US$
                          {(
                            tipsyToUsdt *
                            Math.round(lastStakedUserBalance / 1e18)
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2
                          })}
                        </span>
                      )}
                    </>
                  ) : (
                    <p className="flex space-x-2">
                      <span className="sm:text-2xl text-sm  text-[#0597F2]">
                        0 $TIPSY
                      </span>
                    </p>
                  )}
                </div>
              </div>

              <p className="text-xs text-[#EEFFFD] mt-5 mb-4">
                Your $tipsy balance is on the BNB Chain.
              </p>
            </div>
          </div>
        </div>
      </div>

      {showModal && <ConnectWalletModal onHide={() => setShowModal(false)} />}
      {isMinecraftModal && (
        <MinecraftModal onHide={() => showMinecraftModal(false)} />
      )}
    </>
  );
}
