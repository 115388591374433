import React, { useEffect, useState } from "react";
import { useEthers, useContractFunction } from "@usedapp/core";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { MaxUint256 } from "@ethersproject/constants";

import {
  useBalanceOf,
  useGetAllowance,
  useBalanceOfStakingContract,
} from "../utilities/Web3/tipsycoin";
import {
  useGetUserInfo,
  useGetTotalWeight,
  useGetLockDuration,
  useGetAllocatedGin,
  useGetClaimableTipsy,
  useGetMinStakesAmount,
  useGetUserLvlTxt_Cached,
  useIsExpiredLockDuration,
  useGetUserBalance,
  useGetGinBalance,
} from "../utilities/Web3/tipsystake";
import { useGetTipsyPriceInUSD } from "../utilities/Web3/getExchangeRate";

import { SpinnerCircular } from "spinners-react";
import ConnectWalletModal from "../components/connectWalletModal";
import StakeEnableModal from "../components/StakeEnableModal";
import StakeConfirmModal from "../components/StakeConfirmModal";
import UnstakeConfirmModal from "../components/UnstakeConfirmModal";
import MintTracker from "../components/MintTracker"; // Timer Component

import TipsyStakeAbi from "../abi/TipsyStakeAbi.json";
import TipsyCoinAbi from "../abi/TipsyCoinAbi.json";
import {
  TIPSY_COIN_CONTRACT,
  TIPSY_STAKE_CONTRACT,
} from "../common/environmentVariables";
import { Contract, utils } from "ethers";
import { useNavigate } from "react-router-dom";

// tipsy contract instance to send transaction
const tipsyStakeContractAddress = `${TIPSY_STAKE_CONTRACT}`;
const tipsyStakeContractInterface = new utils.Interface(TipsyStakeAbi);
const tipsyStakeContract = new Contract(
  tipsyStakeContractAddress,
  tipsyStakeContractInterface
);

// tipsy coin contract instance to send transaction
const tipsyCoinContractAddress = `${TIPSY_COIN_CONTRACT}`;
const tipsyCoinContractInterface = new utils.Interface(TipsyCoinAbi);
const tipsyCoinContract = new Contract(
  tipsyCoinContractAddress,
  tipsyCoinContractInterface
);

export default function StakePage() {
  const { account, deactivate, chainId } = useEthers();
  const { balanceOf } = useBalanceOf();
  const navigate = useNavigate();
  const { userInfoMap } = useGetUserInfo();
  const { totalWeight } = useGetTotalWeight();
  const [disabled, setDisabled] = useState(false);
  const { claimableTipsy } = useGetClaimableTipsy();
  const [showModal, setShowModal] = useState(false);
  const [selectedStake, setSelectedStake] = useState(-1);
  const [selectStakeLvl, setSelectedLvlMinStakeAmount] = useState("");
  const { balanceOfStakingContract } = useBalanceOfStakingContract();
  const [showStakeEnableModal, setShowStakeEnableModal] = useState(false);
  const [showStakeConfirmModal, setShowStakeConfirmModal] = useState(false);
  const [showUnstakeConfirmModal, setShowUnstakeConfirmModal] = useState(false);
  const { tier0Stake, tier1Stake, tier2Stake } = useGetMinStakesAmount();
  const { allocatedGin } = useGetAllocatedGin();
  const { ginBalance } = useGetGinBalance();
  const { userLvlTxt } = useGetUserLvlTxt_Cached();
  const { lockDuration } = useGetLockDuration();
  const { isExpiredLockDuration } = useIsExpiredLockDuration();
  const { lastStakedUserBalance } = useGetUserBalance();
  const { userAllowance } = useGetAllowance();
  const { tipsyToUsdt } = useGetTipsyPriceInUSD();
  console.log(`tipsyToUsdt: ${tipsyToUsdt}`);

  console.log(
    `%cTier I ${tier0Stake} || Tier II ${tier1Stake} || Tier III ${tier2Stake}`,
    "color:green"
  );
  console.log(
    "================================================================================================================"
  );
  console.log(
    `%cWallet: ${account} || userAllowance: ${userAllowance} || isExpiredLockDuration: ${isExpiredLockDuration} || LockDuration: ${lockDuration} || Remaining Balance: ${balanceOf} || TipsyStakeContract Balance: ${balanceOfStakingContract} || Claimableable Tipsy: ${claimableTipsy} || UserLevel: ${userLvlTxt} || UserAllocatedGin: ${allocatedGin} || lastStakedUserBalance: ${lastStakedUserBalance}`,
    "color: green"
  );
  console.log(
    "================================================================================================================"
  );

  console.log(
    `%c LastAction ${userInfoMap?.lastAction.toNumber()} || LastRewardBlock ${
      userInfoMap?.lastRewardBlock
    } || LastWeight ${
      userInfoMap?.lastWeight
    } || TotalWeight ${totalWeight} || RewardDebt ${
      userInfoMap?.rewardDebt
    } || RewardEarnedNotMinted ${
      userInfoMap?.rewardEarnedNotMinted
    } || UserLevel ${userInfoMap?.userLevel} || UserMulti ${
      userInfoMap?.userMulti
    }`,
    "color:blue"
  );
  console.log(
    "================================================================================================================"
  );

  // send transaction instance
  const tipsyStakeHandler = useContractFunction(tipsyStakeContract, "stake", {
    transactionName: "Wrap",
  });

  const tipsyStakeHarvestHandler = useContractFunction(
    tipsyStakeContract,
    "harvest",
    {
      transactionName: "Wrap",
    }
  );

  const tipsyCoinApproveHandler = useContractFunction(
    tipsyCoinContract,
    "approve",
    {
      transactionName: "Wrap",
    }
  );

  const tipsyStakeUnstakeHandler = useContractFunction(
    tipsyStakeContract,
    "unstakeAll",
    {
      transactionName: "Wrap",
    }
  );

  useEffect(() => {
    if(chainId !== 56) {
      navigate('/')
    }
  }, [chainId])

  useEffect(() => {
    if (tipsyStakeHandler?.state?.status === "PendingSignature") {
      toast.warning("Staking in progress... Please wait.", {
        autoClose: 20000,
      });
    }
    if (tipsyStakeHandler?.state?.status === "Success") {
      setDisabled(false);
      toast.success("Congratulations! Staked Successfully.", {
        autoClose: 20000,
      });
    }
    if (tipsyStakeHandler?.state?.status === "Exception") {
      setDisabled(false);
      toast.error(tipsyStakeHandler?.state.errorMessage, { autoClose: 20000 });
    }
    if (tipsyStakeHandler?.state?.status === "Fail") {
      setDisabled(false);
      toast.error(tipsyStakeHandler?.state.errorMessage, { autoClose: 20000 });
    }

    delete tipsyStakeHandler?.state["status"];
  }, [tipsyStakeHandler?.state]);

  useEffect(() => {
    if (tipsyStakeHarvestHandler?.state?.status === "PendingSignature") {
      toast.warning("Harvesting in progress... Please wait.", {
        autoClose: 20000,
      });
    }
    if (tipsyStakeHarvestHandler?.state?.status === "Success") {
      setDisabled(false);
      toast.success("Harvested Successfully", {
        autoClose: 20000,
      });
    }
    if (tipsyStakeHarvestHandler?.state?.status === "Exception") {
      setDisabled(false);
      toast.error(tipsyStakeHarvestHandler?.state?.errorMessage, {
        autoClose: 20000,
      });
    }
    if (tipsyStakeHarvestHandler?.state?.status === "Fail") {
      setDisabled(false);
      toast.error(tipsyStakeHarvestHandler?.state?.errorMessage, {
        autoClose: 20000,
      });
    }

    delete tipsyStakeHarvestHandler?.state["status"];
  }, [tipsyStakeHarvestHandler?.state]);

  useEffect(() => {
    if (tipsyCoinApproveHandler.state?.status === "PendingSignature") {
      toast.warning("Pending...", {
        autoClose: 20000,
      });
    }
    if (tipsyCoinApproveHandler.state?.status === "Success") {
      setDisabled(false);
      toast.success("Approval Confirmed", {
        autoClose: 20000,
      });
    }
    if (tipsyCoinApproveHandler.state?.status === "Exception") {
      setDisabled(false);
      toast.error(tipsyCoinApproveHandler.state.errorMessage, {
        autoClose: 20000,
      });
    }
    if (tipsyCoinApproveHandler.state?.status === "Fail") {
      setDisabled(false);
      toast.error(tipsyCoinApproveHandler.state.errorMessage, {
        autoClose: 20000,
      });
    }

    delete tipsyCoinApproveHandler.state["status"];
  }, [tipsyCoinApproveHandler.state]);

  useEffect(() => {
    if (tipsyStakeUnstakeHandler.state?.status === "PendingSignature") {
      toast.warning("Pending...", {
        autoClose: 20000,
      });
    }
    if (tipsyStakeUnstakeHandler.state?.status === "Success") {
      setDisabled(false);
      toast.success("Approval Confirmed", {
        autoClose: 20000,
      });
    }
    if (tipsyStakeUnstakeHandler.state?.status === "Exception") {
      setDisabled(false);
      toast.error(tipsyStakeUnstakeHandler.state.errorMessage, {
        autoClose: 20000,
      });
    }
    if (tipsyStakeUnstakeHandler.state?.status === "Fail") {
      setDisabled(false);
      toast.error(tipsyStakeUnstakeHandler.state.errorMessage, {
        autoClose: 20000,
      });
    }

    delete tipsyStakeUnstakeHandler.state["status"];
  }, [tipsyStakeUnstakeHandler.state]);

  // async function stakeApproveHandler() {
  //   // aprove first  and then do the stake
  //   setDisabled(true);

  //   let stakeAmount;
  //   let tipsyPower = ethers.BigNumber.from(10).pow(ethers.BigNumber.from(18));
  //   if (selectedStake === 0) {
  //     let amount = tier0Stake / 1e18 - lastStakedUserBalance / 1e18;
  //     if (balanceOf / 1e18 > amount) {
  //       // stakeAmount = ethers.BigNumber.from(20000000).mul(tipsyPower);
  //       stakeAmount = amount;

  //       console.log("Silver Stake =>", stakeAmount.toString());
  //     } else {
  //       toast.error("You have insufficient balance");
  //       setDisabled(false);

  //       return;
  //     }
  //   } else if (selectedStake === 1) {

  //     let amount = tier1Stake / 1e18 - lastStakedUserBalance / 1e18;

  //     if (balanceOf / 1e18 > amount) {
  //       // stakeAmount = ethers.BigNumber.from(100000000).mul(tipsyPower);
  //       stakeAmount = amount;
  //       console.log("Gold Stake =>", stakeAmount.toString());
  //     } else {
  //       toast.error("You have insufficient balance");
  //       setDisabled(false);
  //       return;
  //     }
  //   } else if (selectedStake === 2) {
  //     let amount = tier2Stake / 1e18 - lastStakedUserBalance / 1e18;

  //     if (balanceOf / 1e18 > amount) {
  //       // stakeAmount = ethers.BigNumber.from(200000000).mul(tipsyPower);
  //       stakeAmount = amount;

  //       console.log("Platinum Stake =>", stakeAmount.toString());
  //     } else {
  //       toast.error("You have insufficient balance");
  //       setDisabled(false);
  //       return;
  //     }
  //   }
  //   let stakeAmountInWei = ethers.BigNumber.from(ethers.utils.parseEther((stakeAmount).toString()));

  //   console.log("approve stakeAmount", stakeAmountInWei.toString());

  //   // return;
  //   tipsyCoinApproveHandler
  //     .send(tipsyStakeContractAddress, stakeAmountInWei.toString())
  //     .then((data) => {
  //       // if (data.status) {
  //       //   setDisabled(false);
  //       // }
  //     })
  //     .catch((e) => {
  //       setDisabled(false);
  //       toast.error(e?.message);
  //       console.log("error", e?.message);
  //     });
  // }

  async function stakeApproveHandler() {
    setDisabled(true);

    const tipsyPower = ethers.BigNumber.from(10).pow(18);
    let requiredStakeAmount;

    if (selectedStake === 0) {
      requiredStakeAmount = tier0Stake.sub(lastStakedUserBalance);
    } else if (selectedStake === 1) {
      requiredStakeAmount = tier1Stake.sub(lastStakedUserBalance);
    } else if (selectedStake === 2) {
      requiredStakeAmount = tier2Stake.sub(lastStakedUserBalance);
    } else {
      // Handle invalid selectedStake if needed
      return;
    }

    if (balanceOf.gte(requiredStakeAmount)) {
      console.log(
        `${["Silver", "Gold", "Platinum"][selectedStake]} Stake =>`,
        requiredStakeAmount.div(tipsyPower).toString()
      );
    } else {
      toast.error("You have insufficient balance");
      setDisabled(false);
      return;
    }

    // If you reached here, it means balance is sufficient
    tipsyCoinApproveHandler
      .send(tipsyStakeContractAddress, requiredStakeAmount.toString())
      .then((data) => {
        // Handle success if needed
      })
      .catch((e) => {
        setDisabled(false);
        toast.error(e?.message);
        console.log("error", e?.message);
      });
  }

  function stakeConfirmHandler() {
    setDisabled(true);

    const tipsyPower = ethers.BigNumber.from(10).pow(18);
    let requiredStakeAmount;

    if (selectedStake === 0) {
      requiredStakeAmount = tier0Stake.sub(lastStakedUserBalance);
    } else if (selectedStake === 1) {
      requiredStakeAmount = tier1Stake.sub(lastStakedUserBalance);
    } else if (selectedStake === 2) {
      requiredStakeAmount = tier2Stake.sub(lastStakedUserBalance);
    } else {
      // Handle invalid selectedStake if needed
      return;
    }

    if (balanceOf.gte(requiredStakeAmount)) {
      console.log(
        `${["Silver", "Gold", "Platinum"][selectedStake]} Stake =>`,
        requiredStakeAmount.div(tipsyPower).toString()
      );
    } else {
      toast.error("You have insufficient balance");
      setDisabled(false);
      return;
    }

    // If you reached here, it means balance is sufficient
    try {
      tipsyStakeHandler?.send(requiredStakeAmount.toString());
    } catch (error) {
      setDisabled(false);
      toast.error(error?.message);
      console.log("error", error?.message);
    }
  }

  // function stakeConfirmHandler() {
  //   setDisabled(true);
  //   let stakeAmount;
  //   let tipsyPower = ethers.BigNumber.from(10).pow(ethers.BigNumber.from(18));
  //   console.log("selectedStake", selectedStake);

  //   if (selectedStake === 0) {
  //     let amount = tier0Stake / 1e18 - lastStakedUserBalance / 1e18;
  //     console.log("amount stakeAmount", amount);

  //     if (balanceOf / 1e18 > amount) {
  //       // stakeAmount = ethers.BigNumber.from(20000000).mul(tipsyPower);
  //       stakeAmount = amount;

  //       console.log("Silver Stake =>", stakeAmount);
  //     } else {
  //       toast.error("You have insufficient balance");
  //       setDisabled(false);
  //       return;
  //     }
  //   } else if (selectedStake === 1) {
  //     let amount = tier1Stake / 1e18 - lastStakedUserBalance / 1e18;

  //     if (balanceOf / 1e18 > amount) {
  //       // stakeAmount = ethers.BigNumber.from(100000000).mul(tipsyPower);
  //       stakeAmount = amount;

  //       console.log("Gold Stake =>", stakeAmount);
  //     } else {
  //       toast.error("You have insufficient balance");
  //       setDisabled(false);
  //       return;
  //     }
  //   } else if (selectedStake === 2) {
  //     let amount = tier2Stake / 1e18 - lastStakedUserBalance / 1e18;
  //     if (balanceOf / 1e18 > amount) {
  //       // stakeAmount = ethers.BigNumber.from(200000000).mul(tipsyPower);
  //       stakeAmount = amount;

  //       console.log("Platinum Stake =>", stakeAmount);
  //     } else {
  //       toast.error("You have insufficient balance");
  //       setDisabled(false);

  //       return;
  //     }
  //   }
  //   let stakeAmountInWei = ethers.BigNumber.from(ethers.utils.parseEther((stakeAmount).toString()));

  //   console.log("stakeAmount", stakeAmountInWei.toString());
  //   try {
  //     tipsyStakeHandler?.send(stakeAmountInWei.toString());
  //   } catch (error) {
  //     setDisabled(false);
  //     toast.error(error?.message);
  //     console.log("error", error?.message);
  //   }
  // }

  function openStakeEnableModal(lvlTxt, lvlNum) {
    // level = 0, 1, 2
    if (!account) {
      toast.error("Connect Your Wallet");
      return;
    }
    if (userInfoMap?.userLevel > lvlNum && userInfoMap?.userLevel !== 255) {
      toast.error(`You cannot downgrade to tier ${lvlTxt.toUpperCase()}`);
      return;
    }
    setSelectedStake(lvlNum);
    setSelectedLvlMinStakeAmount(lvlNum);
    setShowStakeEnableModal(true);
  }

  function openStakeConfirmModal(lvlTxt, lvlNum) {
    // level = 0, 1, 2
    if (!account) {
      toast.error("Connect Your Wallet");
      return;
    }
    if (userInfoMap?.userLevel > lvlNum && userInfoMap?.userLevel !== 255) {
      toast.error(`You cannot downgrade to tier ${lvlTxt.toUpperCase()}`);
      return;
    }
    setSelectedStake(lvlNum);
    setSelectedLvlMinStakeAmount(lvlNum);
    setShowStakeConfirmModal(true);
  }

  function harvest() {
    try {
      setDisabled(true);
      tipsyStakeHarvestHandler?.send();
    } catch (error) {
      setDisabled(false);
      toast.error(error?.message);
      console.log("error", error?.message);
    }
  }

  function unstakeAll() {
    try {
      setDisabled(true);
      tipsyStakeUnstakeHandler.send();
    } catch (error) {
      setDisabled(false);
      toast.error(error?.message);
      console.log("error", error?.message);
    }
  }

  const disconnectWallet = () => {
    deactivate();
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("shouldConnectMetamask");
  };

  function buyTipsy(url) {
    window.open(url, "_blank");
  }

  return (
    <>
      <div className="2xl:px-52 xl:px-28 lg:px-20 px-2 py-20 text-[#EEFFFD] font-sans">
        {/* Connect Wallet Button */}
        <div className="mb-10 flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:items-center sm:justify-between">
          <div>
            <h2 className="md:text-4xl text-3xl uppercase lg:mb-0 mb-4">
              STAKE TIPSYCOIN
            </h2>
            {!account && (
              <p className="text-xs mt-2">
                Please connect your wallet to stake $tipsy.
              </p>
            )}
          </div>
          <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:items-center sm:justify-between sm:space-x-2">
            <button
              type="button"
              onClick={() =>
                buyTipsy(
                  "https://pancakeswap.finance/swap?outputCurrency=0xe097bcEb09bfb18047Cf259F321cC129b7bEba5e"
                )
              }
              className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
            >
              BUY $TIPSY
            </button>
            {!account ? (
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Connect WALLET
              </button>
            ) : (
              <button
                type="button"
                onClick={() => disconnectWallet()}
                className="border-[#F631A7] uppercase border hover:text-[#EEFFFD] hover:bg-[#F631A7] rounded-[27px] text-sm 2xl:px-16 xl:px-12 lg:px-6 sm:px-10 px-2 py-2 text-center text-white"
              >
                Disconnect WALLET
              </button>
            )}
          </div>
        </div>

        {/* Tipsy Balane & Gin Balnace */}
        <div className="flex xl:flex-row flex-col xl:space-x-8 xl:space-y-0 space-y-4 justify-between">
          <div className="border border-solid border-[#626262] bg-[#292929] rounded-[36px] sm:px-6 px-4 py-8 flex-1">
            <div className="flex items-center justify-between mb-3">
              <p className="uppercase text-sm">$TIPSY BALANCE</p>
            </div>
            <hr className="border border-[#707070]" />

            {/* <p className="text-xs text-[#EEFFFD] mt-8 uppercase flex items-center justify-between"></p> */}

            <div className="mt-3 flex justify-between">
              <div className="flex flex-col">
                <span className="text-xs text-[#EEFFFD]">
                  AVAILABLE TO STAKE
                </span>
                {account && balanceOf !== undefined ? (
                  <>
                    <span className="sm:text-2xl text-sm text-[#0597F2]">
                      {Math.round(balanceOf / 1e18).toLocaleString()} $TIPSY
                    </span>
                    {Math.round(balanceOf / 1e18) > 0 && (
                      <span className="text-sm text-[#F2DB05]">
                        US$
                        {(
                          tipsyToUsdt * Math.round(balanceOf / 1e18)
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    )}
                  </>
                ) : (
                  <p className="flex space-x-2">
                    <span className="sm:text-2xl text-sm text-[#0597F2]">
                      0 $TIPSY
                    </span>
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-[#EEFFFD]">STAKED </span>
                {account && lastStakedUserBalance !== undefined ? (
                  <>
                    <span className="sm:text-2xl text-sm text-[#0597F2]">
                      {Math.round(
                        lastStakedUserBalance / 1e18
                      ).toLocaleString()}{" "}
                      $TIPSY
                    </span>
                    {Math.round(lastStakedUserBalance / 1e18) > 0 && (
                      <span className="text-sm text-[#F2DB05]">
                        US$
                        {(
                          tipsyToUsdt * Math.round(lastStakedUserBalance / 1e18)
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    )}
                  </>
                ) : (
                  <p className="flex space-x-2">
                    <span className="sm:text-2xl text-sm text-[#0597F2]">
                      0 $TIPSY
                    </span>
                  </p>
                )}
              </div>
            </div>

            <p className="text-xs text-[#EEFFFD] mt-5 mb-4">
              Your $tipsy balance is on the BNB Chain.
            </p>
          </div>

          <div className="border border-solid border-[#626262] bg-[#292929] rounded-[36px] sm:px-6 px-4 py-8 flex-1">
            <div className="flex items-center justify-between mb-3">
              <p className="uppercase text-sm">$GIN BALANCE</p>
            </div>
            <hr className="border border-[#707070]" />

            <div className="flex items-start md:space-x-6 space-x-2 justify-between mt-3 uppercase">
              {account && ginBalance !== undefined ? (
                <p className="text-xs text-[#EEFFFD]">
                  BNB Chain <br />
                  <span className="text-[#F2DB05] md:text-xl text-lg">
                    {ginBalance.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </p>
              ) : (
                <p className="text-xs text-[#EEFFFD]">
                  BNB Chain <br />
                  <span className="text-[#F2DB05] md:text-xl text-lg">0</span>
                </p>
              )}
              {/* <p className="text-xs text-[#EEFFFD]">
                Polygon <br /> <span className="text-[#F2DB05] md:text-xl text-lg">0</span>
              </p> */}
              <p className="text-xs text-[#EEFFFD]">
                ETH Mainnet <br />{" "}
                <span className="text-[#F2DB05] md:text-xl text-lg">0</span>
              </p>
              <p className="h-10 border border-[#707070]"></p>
              <p className="text-xs text-[#EEFFFD]">
                Tipsy Server <br />
                <span className="text-[#F2DB05] md:text-xl text-lg">0</span>
              </p>
            </div>

            <hr className="border border-[#707070] my-2" />
            {account && allocatedGin !== undefined && allocatedGin > 0 && (
              <>
                <p className="text-xs text-[#EEFFFD] uppercase">
                  Total Reserved
                  <br />
                  <span className="text-[#F2DB05] md:text-xl text-lg">
                    {allocatedGin.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                    $GIN
                  </span>
                </p>
                <p className="text-xs text-[#EEFFFD]">
                  Gin is now live! Your $gin will be minted the next time you
                  stake, unstake, or harvest
                </p>
              </>
            )}
            {account &&
              allocatedGin !== undefined &&
              allocatedGin === 0 &&
              ginBalance !== undefined && (
                <>
                  <p className="text-xs text-[#EEFFFD] uppercase">
                    Total Gin Balance
                    <br />
                    <span className="text-[#F2DB05] md:text-xl text-lg">
                      {ginBalance.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{" "}
                      $GIN
                    </span>
                  </p>
                </>
              )}
          </div>
        </div>

        {/* Tiers */}
        <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 justify-between mt-10">
          {/* Silver */}
          <div className="w-full relative overflow-hidden">
            {account &&
              userInfoMap?.userLevel === 0 &&
              userInfoMap?.lastAction.toNumber() !== 0 && (
                <div className="absolute transform rotate-45 bg-[#F2DB05] text-center text-black font-semibold text-sm py-1 right-[-30px] top-[15px] w-[125px]">
                  Active
                </div>
              )}
            <div
              className={`border border-solid border-[#626262] ${
                account &&
                userInfoMap?.userLevel === 0 &&
                userInfoMap?.lastAction.toNumber() !== 0
                  ? "bg-[#EAF0E7] text-[#000000]"
                  : "bg-[#292929] text-[#EEFFFD]"
              } rounded-[36px] px-6 py-8 flex-1`}
            >
              <div
                className={`flex items-center justify-between mb-3 ${
                  account &&
                  userInfoMap?.userLevel === 0 &&
                  userInfoMap?.lastAction.toNumber() !== 0
                    ? "text-[#000000] pr-7"
                    : "text-[#31CC62]"
                }`}
              >
                <p className="uppercase text-sm">Tier I</p>
                <div className="uppercase text-sm">
                  {userInfoMap?.userLevel === 0 &&
                  !isExpiredLockDuration &&
                  lockDuration !== undefined ? (
                    <MintTracker
                      lockDuration={lockDuration.toNumber()}
                      time={userInfoMap?.lastAction.toNumber()}
                    />
                  ) : (
                    "90 Days"
                  )}
                </div>
              </div>
              <hr className="border border-[#707070]" />

              <div className="flex flex-col mt-8">
                <span className="text-xs">STAKE</span>
                <span className="sm:text-2xl text-lg text-[#F631A7]">
                  20,000,000 $TIPSY
                </span>
              </div>

              <div className="flex flex-col mt-4 uppercase">
                <span className="text-xs">Daily Reward</span>
                <span className="sm:text-2xl text-lg text-[#0597F2]">
                  100 $GIN
                </span>
              </div>

              {userInfoMap?.userLevel === 0 &&
              userInfoMap?.lastAction.toNumber() !== 0 &&
              claimableTipsy !== undefined ? (
                <div className="flex flex-col mt-4 uppercase">
                  <span className="text-xs">Claimable</span>
                  <span className="sm:text-2xl text-lg text-[#0597F2]">
                    {(claimableTipsy / 1e18).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                    $GIN
                  </span>
                </div>
              ) : (
                <div className="flex flex-col mt-4 uppercase">
                  <span className="text-xs">Claimable</span>
                  <span className="sm:text-2xl text-lg text-[#0597F2]">
                    0 $GIN
                  </span>
                </div>
              )}

              {/* <div className="flex items-center justify-between mt-4 mb-2">
                <p className="uppercase text-xs">BENEFITS</p>
              </div>
              <hr className="border border-[#707070]" />

              <div className="text-xs mt-5 flex items-center justify-between">
                <span>NFT Discounts:</span>
                <span>2%</span>
              </div>

              <div className="text-xs mt-2 flex items-center justify-between">
                <span>In-Game Boost:</span>
                <span>2%</span>
              </div> */}

              {account &&
              userInfoMap?.userLevel === 0 &&
              userInfoMap?.lastAction.toNumber() !== 0 ? (
                <>
                  <button
                    onClick={() => harvest()}
                    disabled={!account || disabled}
                    type="button"
                    className={`bg-[#000000] ${
                      !account || disabled
                        ? "text-[#828282] border-[#828282] cursor-not-allowed"
                        : "text-[#EEFFFD] cursor-pointer"
                    } w-full rounded-[27px] text-sm px-6 py-2 mt-10 text-center`}
                  >
                    HARVEST
                  </button>

                  <button
                    onClick={() => setShowUnstakeConfirmModal(true)}
                    disabled={!account || disabled || !isExpiredLockDuration}
                    type="button"
                    className={`bg-[#000000] ${
                      !account || disabled || !isExpiredLockDuration
                        ? "text-[#828282] border-[#828282] cursor-not-allowed"
                        : "text-[#EEFFFD] cursor-pointer"
                    } w-full rounded-[27px] text-sm px-6 py-2 mt-1 text-center`}
                  >
                    UNSTAKE
                  </button>
                </>
              ) : userAllowance < 20000000 - lastStakedUserBalance / 1e18 ? (
                <button
                  onClick={() => openStakeEnableModal("Tipsy Silver", 0)}
                  disabled={
                    !account ||
                    disabled ||
                    userInfoMap?.userLevel === 1 ||
                    userInfoMap?.userLevel === 2
                  }
                  type="button"
                  className={`bg-none ${
                    !account ||
                    disabled ||
                    userInfoMap?.userLevel === 1 ||
                    userInfoMap?.userLevel === 2
                      ? "text-[#828282] border-[#828282] cursor-not-allowed"
                      : "text-[#31CC62] border-[#31CC62] cursor-pointer"
                  } border w-full rounded-[27px] text-sm px-6 py-2 mt-10 uppercase text-center`}
                >
                  Enable Staking
                </button>
              ) : (
                <button
                  onClick={() => openStakeConfirmModal("Tipsy Silver", 0)}
                  disabled={
                    !account ||
                    disabled ||
                    userInfoMap?.userLevel === 1 ||
                    userInfoMap?.userLevel === 2
                  }
                  type="button"
                  className={`bg-none ${
                    !account ||
                    disabled ||
                    userInfoMap?.userLevel === 1 ||
                    userInfoMap?.userLevel === 2
                      ? "text-[#828282] border-[#828282] cursor-not-allowed"
                      : "text-[#31CC62] border-[#31CC62] cursor-pointer"
                  } border w-full rounded-[27px] text-sm px-6 py-2 mt-10 text-center`}
                >
                  STAKE
                </button>
              )}
            </div>
          </div>
          {/* Gold */}
          <div className="w-full relative overflow-hidden">
            {account &&
              userInfoMap?.userLevel === 1 &&
              userInfoMap?.lastAction.toNumber() !== 0 && (
                <div className="absolute transform rotate-45 bg-[#F2DB05] text-center text-black font-semibold text-sm py-1 right-[-30px] top-[15px] w-[125px]">
                  Active
                </div>
              )}
            <div
              className={`border border-solid border-[#626262] ${
                account &&
                userInfoMap?.userLevel === 1 &&
                userInfoMap?.lastAction.toNumber() !== 0
                  ? "bg-[#EAF0E7] text-[#000000]"
                  : "bg-[#292929] text-[#EEFFFD]"
              } rounded-[36px] px-6 py-8 flex-1`}
            >
              <div
                className={`flex items-center justify-between mb-3 ${
                  account &&
                  userInfoMap?.userLevel === 1 &&
                  userInfoMap?.lastAction.toNumber() !== 0
                    ? "pr-7"
                    : ""
                }`}
              >
                <p className="uppercase text-sm">Tier II</p>
                <div className="uppercase text-sm">
                  {userInfoMap?.userLevel === 1 &&
                  !isExpiredLockDuration &&
                  lockDuration !== undefined ? (
                    <MintTracker
                      lockDuration={lockDuration.toNumber()}
                      time={userInfoMap?.lastAction.toNumber()}
                    />
                  ) : (
                    "90 Days"
                  )}
                </div>
              </div>
              <hr className="border border-[#707070]" />

              <div className="flex flex-col mt-8">
                <span className="text-xs">STAKE</span>
                <span className="sm:text-2xl text-lg text-[#F631A7]">
                  100,000,000 $TIPSY
                </span>
              </div>

              <div className="flex flex-col mt-4 uppercase">
                <span className="text-xs">Daily Reward</span>
                <span className="sm:text-2xl text-lg text-[#0597F2]">
                  600 $GIN
                </span>
              </div>

              {userInfoMap?.userLevel === 1 &&
              userInfoMap?.lastAction.toNumber() !== 0 &&
              claimableTipsy !== undefined ? (
                <div className="flex flex-col mt-4 uppercase">
                  <span className="text-xs">Claimable</span>
                  <span className="sm:text-2xl text-lg text-[#0597F2]">
                    {(claimableTipsy / 1e18).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                    $GIN
                  </span>
                </div>
              ) : (
                <div className="flex flex-col mt-4 uppercase">
                  <span className="text-xs">Claimable</span>
                  <span className="sm:text-2xl text-lg text-[#0597F2]">
                    0 $GIN
                  </span>
                </div>
              )}

              {/* <div className="flex items-center justify-between mt-4 mb-2">
                <p className="uppercase text-xs">BENEFITS</p>
              </div>
              <hr className="border border-[#707070]" />

              <div className="text-xs mt-5 flex items-center justify-between">
                <span>NFT Discounts:</span>
                <span>10%</span>
              </div>

              <div className="text-xs mt-2 flex items-center justify-between">
                <span>In-Game Boost:</span>
                <span>5%</span>
              </div> */}

              {account &&
              userInfoMap?.userLevel === 1 &&
              userInfoMap?.lastAction.toNumber() !== 0 ? (
                <>
                  <button
                    onClick={() => harvest()}
                    disabled={!account || disabled}
                    type="button"
                    className={`bg-[#000000] ${
                      !account || disabled
                        ? "text-[#828282] border-[#828282] cursor-not-allowed"
                        : "text-[#EEFFFD] cursor-pointer"
                    } w-full rounded-[27px] text-sm px-6 py-2 mt-10 text-center`}
                  >
                    HARVEST
                  </button>

                  <button
                    onClick={() => setShowUnstakeConfirmModal(true)}
                    disabled={!account || disabled || !isExpiredLockDuration}
                    type="button"
                    className={`bg-[#000000] ${
                      !account || disabled || !isExpiredLockDuration
                        ? "text-[#828282] border-[#828282] cursor-not-allowed"
                        : "text-[#EEFFFD] cursor-pointer"
                    } w-full rounded-[27px] text-sm px-6 py-2 mt-1 text-center`}
                  >
                    UNSTAKE
                  </button>
                </>
              ) : userAllowance < 100000000 - lastStakedUserBalance / 1e18 ? (
                <button
                  onClick={() => openStakeEnableModal("Tipsy Gold", 1)}
                  type="button"
                  disabled={
                    !account || disabled || userInfoMap?.userLevel === 2
                  }
                  className={`bg-none ${
                    !account ||
                    disabled ||
                    userInfoMap?.userLevel === 1 ||
                    userInfoMap?.userLevel === 2
                      ? "text-[#828282] border-[#828282] cursor-not-allowed"
                      : "text-[#EEFFFD] border-[#EEFFFD] cursor-pointer"
                  } border w-full rounded-[27px] text-sm px-6 py-2 uppercase mt-10 text-center`}
                >
                  Enable Staking
                </button>
              ) : (
                <button
                  onClick={() => openStakeConfirmModal("Tipsy Gold", 1)}
                  type="button"
                  disabled={
                    !account || disabled || userInfoMap?.userLevel === 2
                  }
                  className={`bg-none ${
                    !account || disabled || userInfoMap?.userLevel === 2
                      ? "text-[#828282] border-[#828282] cursor-not-allowed"
                      : "text-[#EEFFFD] border-[#EEFFFD] cursor-pointer"
                  } border w-full rounded-[27px] text-sm px-6 py-2 mt-10 text-center`}
                >
                  {account &&
                  userInfoMap?.userLevel === 0 &&
                  userInfoMap?.userLevel !== 255 &&
                  userInfoMap?.lastWeight > 1000
                    ? "UPGRADE"
                    : "STAKE"}
                </button>
              )}
            </div>
          </div>
          {/* Platinum */}
          <div className="w-full relative overflow-hidden">
            {account &&
              userInfoMap?.userLevel === 2 &&
              userInfoMap?.lastAction.toNumber() !== 0 && (
                <div className="absolute transform rotate-45 bg-[#F2DB05] text-center text-black font-semibold text-sm py-1 right-[-30px] top-[15px] w-[125px]">
                  Active
                </div>
              )}
            <div
              className={`border border-solid border-[#626262] ${
                account &&
                userInfoMap?.userLevel === 2 &&
                userInfoMap?.lastAction.toNumber() !== 0
                  ? "bg-[#EAF0E7] text-[#000000]"
                  : "bg-[#292929] text-[#EEFFFD]"
              } rounded-[36px] px-6 py-8 flex-1`}
            >
              <div
                className={`flex items-center justify-between mb-3 ${
                  account &&
                  userInfoMap?.userLevel === 2 &&
                  userInfoMap?.lastAction.toNumber() !== 0
                    ? "text-[#000000] pr-7"
                    : "text-[#F2DB05]"
                }`}
              >
                <p className="uppercase text-sm">Tier III</p>
                <div className="uppercase text-sm">
                  {userInfoMap?.userLevel === 2 &&
                  !isExpiredLockDuration &&
                  lockDuration !== undefined ? (
                    <MintTracker
                      lockDuration={lockDuration.toNumber()}
                      time={userInfoMap?.lastAction.toNumber()}
                    />
                  ) : (
                    "90 Days"
                  )}
                </div>
              </div>
              <hr className="border border-[#707070]" />

              <div className="flex flex-col mt-8">
                <span className="text-xs">STAKE</span>
                <span className="sm:text-2xl text-lg text-[#F631A7]">
                  200,000,000 $TIPSY
                </span>
              </div>

              <div className="flex flex-col mt-4 uppercase">
                <span className="text-xs">Daily Reward</span>
                <span className="sm:text-2xl text-lg text-[#0597F2]">
                  1,400 $GIN
                </span>
              </div>

              {userInfoMap?.userLevel === 2 &&
              userInfoMap?.lastAction.toNumber() !== 0 &&
              claimableTipsy !== undefined ? (
                <div className="flex flex-col mt-4 uppercase">
                  <span className="text-xs">Claimable</span>
                  <span className="sm:text-2xl text-lg text-[#0597F2]">
                    {(claimableTipsy / 1e18).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                    $GIN
                  </span>
                </div>
              ) : (
                <div className="flex flex-col mt-4 uppercase">
                  <span className="text-xs">Claimable</span>
                  <span className="sm:text-2xl text-lg text-[#0597F2]">
                    0 $GIN
                  </span>
                </div>
              )}

              {/* <div className="flex items-center justify-between mt-4 mb-2">
                <p className="uppercase text-xs">BENEFITS</p>
              </div>
              <hr className="border border-[#707070]" />

              <div className="text-xs mt-5 flex items-center justify-between">
                <span>NFT Discounts:</span>
                <span>20%</span>
              </div>
              

              <div className="text-xs mt-2 flex items-center justify-between">
                <span>In-Game Boost:</span>
                <span>10%</span>
              </div> */}

              {account &&
              userInfoMap?.userLevel === 2 &&
              userInfoMap?.lastAction.toNumber() !== 0 ? (
                <>
                  <button
                    onClick={() => harvest()}
                    type="button"
                    disabled={!account || disabled}
                    className={`bg-[#292929] ${
                      !account || disabled
                        ? "text-[#828282] border-[#828282] cursor-not-allowed"
                        : "text-[#F2DB05] border-[#F2DB05] cursor-pointer"
                    } border w-full rounded-[27px] text-sm px-6 py-2 mt-10 text-center`}
                  >
                    HARVEST
                  </button>

                  <button
                    onClick={() => setShowUnstakeConfirmModal(true)}
                    type="button"
                    disabled={!account || disabled || !isExpiredLockDuration}
                    className={`bg-[#292929] ${
                      !account || disabled || !isExpiredLockDuration
                        ? "text-[#828282] border-[#828282] cursor-not-allowed"
                        : "text-[#F2DB05] border-[#F2DB05] cursor-pointer"
                    } border w-full rounded-[27px] text-sm px-6 py-2 mt-1 text-center`}
                  >
                    UNSTAKE
                  </button>
                </>
              ) : userAllowance < 200000000 - lastStakedUserBalance / 1e18 ? (
                <button
                  onClick={() => openStakeEnableModal("Tipsy Platinum", 2)}
                  disabled={!account || disabled}
                  type="button"
                  className={`bg-[#292929] ${
                    !account || disabled
                      ? "text-[#828282] border-[#828282] cursor-not-allowed"
                      : "text-[#F2DB05] border-[#F2DB05] cursor-pointer"
                  } border w-full rounded-[27px] text-sm px-6 py-2 uppercase mt-10 text-center`}
                >
                  Enable Staking
                </button>
              ) : (
                <button
                  onClick={() => openStakeConfirmModal("Tipsy Platinum", 2)}
                  disabled={!account || disabled}
                  type="button"
                  className={`bg-[#292929] ${
                    !account || disabled
                      ? "text-[#828282] border-[#828282] cursor-not-allowed"
                      : "text-[#F2DB05] border-[#F2DB05] cursor-pointer"
                  } border w-full rounded-[27px] text-sm px-6 py-2 mt-10 text-center`}
                >
                  {account === undefined || userInfoMap?.lastWeight < 1000
                    ? "STAKE"
                    : "UPGRADE"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && <ConnectWalletModal onHide={() => setShowModal(false)} />}
      {showStakeEnableModal && (
        <StakeEnableModal
          onAction={() => {
            setShowStakeEnableModal(false);
            stakeApproveHandler();
          }}
          onHide={() => {
            setShowStakeEnableModal(false);
          }}
        />
      )}
      {showStakeConfirmModal && (
        <StakeConfirmModal
          onAction={() => {
            setShowStakeConfirmModal(false);
            stakeConfirmHandler();
          }}
          onHide={() => {
            setShowStakeConfirmModal(false);
          }}
        />
      )}
      {showUnstakeConfirmModal && (
        <UnstakeConfirmModal
          onAction={() => {
            setShowUnstakeConfirmModal(false);
            unstakeAll();
          }}
          onHide={() => {
            setShowUnstakeConfirmModal(false);
          }}
        />
      )}
    </>
  );
}
