import React from 'react'
import { useTimer } from 'react-timer-hook'

export default function MintTracker({ time, lockDuration }) {
  let newTimeInSec = (time + lockDuration) * 1000

  let expiryTimestamp = new Date(newTimeInSec)
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds())

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => {},
    format: '24-hour',
  })

  return (
    <div className="font-cool text-center">
      {
        <div className="flex items-center text-center justify-center space-x-1">
          <div className="">
            <p className="text-sm">{days}d</p>
          </div>
          <div className="">
            <p className="text-sm">{hours}h</p>
          </div>
          <div className="">
            <p className="text-sm">{minutes}m</p>
          </div>
          <div className="">
            <p className="text-sm">{seconds}s</p>
          </div>
        </div>
      }
    </div>
  )
}
